export const lessonExpectationMetOptions = [
  { label: "全くできなかった", value: 1 },
  { label: "どちらかといえばできなかった", value: 2 },
  { label: "どちらとも言えない", value: 3 },
  { label: "どちらかといえばできた", value: 4 },
  { label: "十分にできた", value: 5 }
];

export const messageDeliveredToStudentOptions = [
  { label: "全く思わない", value: 1 },
  { label: "どちらかといえばそう思わない", value: 2 },
  { label: "どちらとも言えない", value: 3 },
  { label: "どちらかといえばそう思う", value: 4 },
  { label: "とてもそう思う", value: 5 }
];

export const supportSatisfactionOptions = [
  { label: "全く満足していない", value: 1 },
  { label: "どちらかといえば満足していない", value: 2 },
  { label: "どちらとも言えない", value: 3 },
  { label: "どちらかといえば満足している", value: 4 },
  { label: "とても満足している", value: 5 }
];

export const futureParticipationOptions = [
  { label: "今後は登壇したくない", value: 1 },
  { label: "どちらかといえば登壇したくない", value: 2 },
  { label: "どちらとも言えない", value: 3 },
  { label: "どちらかといえば登壇したい", value: 4 },
  { label: "ぜひまた登壇したい", value: 5 }
];
