import * as yup from "yup";

export type beforeQuestionnairesFormSchemaType = {
  schoolClass: string;
  studentIDNumber: string;
  isSurvey: string;
  howMuchFun: string;
  wantToLearn: string;
};

const beforeQuestionnairesFormSchema: yup.ObjectSchema<beforeQuestionnairesFormSchemaType> =
  yup.object().shape({
    schoolClass: yup.string().required("かならずかいてください"),
    studentIDNumber: yup.string().required("かならずかいてください"),
    isSurvey: yup.string().required("かならずかいてください"),
    howMuchFun: yup.string().required("かならずかいてください"),
    wantToLearn: yup.string().required("かならずかいてください")
  });

export default beforeQuestionnairesFormSchema;
