import Axios from "axios";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

import { useAlertMessages } from "hooks/useAlertMessages";

import {
  ContactCorsProxyLocal,
  ContactCorsProxyNetwork
} from "constants/ContactFormKeys";
import { add } from "date-fns";
import { beforeQuestionnairesFormSchemaType } from "./constants/beforeQuestionnairesFormSchema";
import {
  beforeQuestionnairesProductionFormKeys,
  beforeQuestionnairesDevelopmentFormKeys
} from "./constants/beforeQuestionnairesFormKeys";

import { studentQuestionnairesDataAtom } from "../../studentQuestionnaires/new/store";

const useQuestionnairesApi = (lessonId: string, unique_id: string) => {
  const history = useHistory();
  const { addErrorMessage } = useAlertMessages();

  const [studentQuestionnairesData, setStudentQuestionnairesData] = useAtom(
    studentQuestionnairesDataAtom
  );

  const CorsProxy =
    process.env.REACT_APP_BUILD_ENV === "development"
      ? ContactCorsProxyLocal
      : ContactCorsProxyNetwork;
  const beforeQuestionnairesFormKeys =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? beforeQuestionnairesProductionFormKeys
      : beforeQuestionnairesDevelopmentFormKeys;

  const { isLoading } = useAspidaSWR(
    aspida({}).api.v1.lessons._lessonId_string(lessonId).student_questionnaires
      .new,
    {
      onSuccess: (data) => {
        setStudentQuestionnairesData(data);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          if (studentQuestionnairesData?.is_plain_questionnaires) {
            addErrorMessage(
              "URLがまちがっています。せんせいにきいてみてください。"
            );
            return;
          }
          addErrorMessage("URLが間違っています。先生に確認してください。");
          return;
        }
        if (studentQuestionnairesData?.is_plain_questionnaires) {
          addErrorMessage("アンケートをよみこめません");
          return;
        }
        addErrorMessage("アンケート情報の取得に失敗しました");
      },
      query: {
        unique_id
      }
    }
  );

  const submitBeforeQuestionnaireForm = async (
    data: beforeQuestionnairesFormSchemaType
  ) => {
    if (!studentQuestionnairesData) {
      addErrorMessage("アンケート情報の取得に失敗しているため送信できません");
      return;
    }

    const formattedData = new FormData();

    formattedData.append(
      beforeQuestionnairesFormKeys.lessonRequestIdKeys,
      studentQuestionnairesData.lesson_request_id?.toString() || ""
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.schoolPrefectureNameKeys,
      studentQuestionnairesData?.school_prefecture_name || ""
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.schoolNameKeys,
      studentQuestionnairesData?.school_name || ""
    );

    formattedData.append(
      beforeQuestionnairesFormKeys.schoolClassKeys,
      data.schoolClass
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.studentIDNumberKeys,
      data.studentIDNumber
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.isSurveyKeys,
      data.isSurvey
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.howMuchFunKeys,
      data.howMuchFun
    );
    formattedData.append(
      beforeQuestionnairesFormKeys.wantToLearnKeys,
      data.wantToLearn
    );

    await Axios.post(
      CorsProxy + beforeQuestionnairesFormKeys.formUrl,
      formattedData
    )
      .then(() => {
        setStudentQuestionnairesData(undefined);
        if (studentQuestionnairesData.is_plain_questionnaires) {
          history.push(
            `/lessons/${studentQuestionnairesData.lesson_id}/student_before_questionnaires/complete/hiragana`
          );
          return;
        }
        history.push(
          `/lessons/${studentQuestionnairesData.lesson_id}/student_before_questionnaires/complete`
        );
      })
      .catch((error) => {
        console.log(error.response);
        addErrorMessage("アンケートの送信に失敗しました");
      });
  };

  return {
    isLoading,
    submitBeforeQuestionnaireForm
  };
};

export default useQuestionnairesApi;
