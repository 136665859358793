import React from "react";
import { useAtomValue } from "jotai";
import { FormProvider, UseFormReturn } from "react-hook-form";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_teacher_questionnaires";
import { afterLessonSurveyDataAtom } from "../store";

import {
  lessonExpectationMetOptions,
  messageDeliveredToStudentOptions,
  supportSatisfactionOptions,
  futureParticipationOptions
} from "../constants/afterLessonSurveyFormOptions";

type AfterLessonSurveyFormProps = {
  methods: UseFormReturn<Methods["post"]["reqBody"]>;
  onSubmit: () => void;
};

const AfterLessonSurveyForm: React.FC<AfterLessonSurveyFormProps> = (props) => {
  const { methods, onSubmit } = props;
  const theme = useTheme();
  const afterLessonSurveyData = useAtomValue(afterLessonSurveyDataAtom);

  const publicationAnswerOptions =
    afterLessonSurveyData?.publication_answers.map((answer) => ({
      label: answer.name,
      value: answer.id
    }));

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Typography variant="h2">
            以下のアンケートに回答してください
          </Typography>
          <FormRadioGroup
            name="lesson_expectation_met"
            label="イメージしていたとおりの授業が実施できましたか？"
            options={lessonExpectationMetOptions}
            isRequired
            row
          />
          <FormRadioGroup
            name="message_delivered_to_student"
            label="子どもたちに、自分の伝えたいことが伝わったと思いますか？"
            options={messageDeliveredToStudentOptions}
            isRequired
            row
          />
          <FormTextArea
            name="feedback_comments"
            label="今回の登壇に対するご感想を自由に教えてください。"
            placeholder=""
            isRequired
          />
          <FormRadioGroup
            name="support_satisfaction"
            label="弊社のサポートに対する満足度を教えてください。"
            options={supportSatisfactionOptions}
            isRequired
            row
          />
          <FormTextArea
            name="support_satisfaction_reason"
            label="サポートに対する満足度の理由を教えてください。"
            placeholder=""
            isRequired
          />
          <FormRadioGroup
            name="publication_answer_id"
            label="今回の授業を公開してもよろしいですか？"
            options={publicationAnswerOptions || []}
            isRequired
            row
          />
          <FormRadioGroup
            name="is_introduce_to_affiliation"
            label="自身の所属先にも複業先生のことを紹介したいですか？"
            options={[
              { label: "はい", value: true },
              { label: "いいえ", value: false }
            ]}
            isRequired
          />
          <FormRadioGroup
            name="future_participation"
            label="今後も弊社の授業に登壇したいですか？"
            options={futureParticipationOptions}
            isRequired
            row
          />
          <FormTextArea
            name="additional_comments"
            label="その他、コメントがございましたら自由にご記入ください。"
            placeholder=""
          />
          <FormSubmitButton label="回答を送信" />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AfterLessonSurveyForm;
