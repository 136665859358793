/* eslint-disable import/order */
import type React from "react";
import theme from "./themes/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from "jotai";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Routes
import GuestRoute from "components/routes/GuestRoute";
import PrivateRoute from "components/routes/PrivateRoute";
import SchoolGuestRoute from "components/routes/SchoolGuestRoute";
import SchoolPrivateRoute from "components/routes/SchoolPrivateRoute";
import ScrollToTop from "components/organisms/ScrollToTop";
// root pages
import Signup from "features/root/signup/page";
import Contact from "features/root/contact/page";
import LPClassi from "features/root/lp/classi/page";
import Home from "features/root/index/page";
import NotFound from "features/root/notfound/page";
import Privacy from "features/root/privacy/page";
import SchoolHome from "features/root/lp/school/page";
// recruitments
import RecruitmentIndex from "features/recruitments/index/page";
import RecruitmentNew from "features/recruitments/new/page";
import RecruitmentShow from "features/recruitments/id/page";
// requests
import RequestsCreate from "features/requests/thenksRequest/page";
import RequestsNew from "features/requests/new/page";
import MeetingSheet from "features/requests/meetingSheet/page";
// accountDeletion
import AccountDeletionRequest from "features/accountDeletion/new/page";
import AccountDeletionRequestComplete from "features/accountDeletion/complete/page";
// teachers
import ForgotPassword from "features/teachers/auth/forgotPassword/page";
import TeachersIndex from "features/teachers/index/page";
import ELearningNew from "features/teachers/mypage/eLearnings/new/page";
import ElearningResult from "features/teachers/mypage/eLearnings/result/page";
import TeacherRequestShow from "features/teachers/mypage/requests/id/page";
import Settings from "features/teachers/mypage/settings/page";
import TeacherPasswordReset from "features/teachers/auth/passwordReset/page";
import TeacherPasswordResetComplete from "features/teachers/auth/passwordResetComplete/page";
import TeacherProfileToNew from "features/teachers/profile/toNew/page";
import TeacherShow from "features/teachers/id/page";
import TeacherThanksResetRequest from "features/teachers/auth/thanksResetRequest/page";
import TeacherLogin from "./features/teachers/auth/login/page";
import TeacherMypage from "features/teachers/mypage/index/page";
import TeacherMyRecruitments from "features/teachers/mypage/recruitments/index/page";
import TeacherMyLessons from "features/teachers/mypage/lessons/index/page";
import TeacherMyRequests from "features/teachers/mypage/requests/index/page";
import TeacherProfileComplete from "./features/teachers/profile/complete/page";
import TeacherProfileEdit from "./features/teachers/profile/edit/page";
import TeacherProfileNew from "./features/teachers/profile/new/page";
import TeacherSignup from "./features/teachers/auth/signup/page";
import TeacherThanksSignup from "./features/teachers/auth/thanksSignup/page";
import TeacherLessonShow from "./features/teachers/mypage/lessons/id/page";
import TeacherAfterLessonSurvey from "./features/lessons/id/afterLessonSurvey/new/page";
import TeacherSendedAfterLessonSurvey from "./features/lessons/id/afterLessonSurvey/complete/page";

// schools
import SchoolForgotPassword from "features/schools/auth/forgotPassword/page";
import SchoolLogin from "features/schools/auth/login/page";
import AiTeachingPlanCreate from "features/schools/mypage/aiTeachingPlan/result/page";
import AiTeachingPlanNew from "features/schools/mypage/aiTeachingPlan/new/page";
import SchoolRequestShow from "features/schools/mypage/requests/id/page";
import SchoolMypage from "features/schools/mypage/index/page";
import SchoolMyRecruitments from "features/schools/mypage/recruitments/index/page";
import SchoolMyLessons from "features/schools/mypage/lessons/index/page";
import SchoolMyRequests from "features/schools/mypage/requests/index/page";
import SchoolPasswordReset from "features/schools/auth/passwordReset/page";
import SchoolPasswordResetComplete from "features/schools/auth/passwordResetComplete/page";
import SchoolChangeEmail from "features/schools/mypage/changeEmail/page";
import ChangePassword from "features/schools/mypage/changePassword/page";
import SchoolProfileComplete from "features/schools/profile/complete/page";
import SchoolProfileEdit from "features/schools/profile/edit/page";
import SchoolProfileNew from "features/schools/profile/new/page";
import SchoolProfileToNew from "features/schools/profile/toNew/page";
import RecruitmentShowForSchool from "features/schools/mypage/recruitments/id/page";
import SchoolSignup from "features/schools/auth/signup/page";
import SchoolThanksResetRequest from "features/schools/auth/thanksResetRequest/page";
import SchoolThanksSignup from "features/schools/auth/thanksSignup/page";
import SchoolLessonShow from "./features/schools/mypage/lessons/id/page";
import StudentBeforeQuestionnaire from "./features/lessons/id/studentBeforeQuestionnaires/new/page";
import SendedBeforeQuestionnaires from "./features/lessons/id/studentBeforeQuestionnaires/complete/page";
import StudentAfterQuestionnaire from "./features/lessons/id/studentAfterQuestionnaires/new/page";
import SendedAfterQuestionnaires from "./features/lessons/id/studentAfterQuestionnaires/complete/page";
import FavoriteTeachersIndex from "features/schools/mypage/favoriteTeachers/index/page";
import StudentBeforeQuestionnaireHiragana from "./features/lessons/id/studentBeforeQuestionnaires/new/hiragana/page";
import SendedBeforeQuestionnairesHiragana from "./features/lessons/id/studentBeforeQuestionnaires/complete/hiragana/page";
import StudentAfterQuestionnaireHiragana from "./features/lessons/id/studentAfterQuestionnaires/new/hiragana/page";
import SendedAfterQuestionnairesHiragana from "./features/lessons/id/studentAfterQuestionnaires/complete/hiragana/page";

function App(): React.ReactElement {
  return (
    <div className="App">
      <Provider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/signup" component={Signup} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/contact" component={Contact} />
              <Route
                path="/lp"
                render={({ match: { url } }) => (
                  <Switch>
                    <Route path={`${url}/school`} component={SchoolHome} />
                    <Route path={`${url}/classi`} component={LPClassi} />
                  </Switch>
                )}
              />
              <Route path="/not-found" component={NotFound} />
              {/* 複業先生向けRoute */}
              <Route
                path="/teachers"
                render={({ match: { url } }) => (
                  <Switch>
                    <Route exact path={url} component={TeachersIndex} />
                    <GuestRoute
                      path={`${url}/login`}
                      component={TeacherLogin}
                    />
                    <GuestRoute
                      path={`${url}/signup`}
                      component={TeacherSignup}
                    />
                    <GuestRoute
                      path={`${url}/thanks-signup`}
                      component={TeacherThanksSignup}
                    />
                    <GuestRoute
                      path={`${url}/forgot-password`}
                      component={ForgotPassword}
                    />
                    <GuestRoute
                      path={`${url}/password-reset`}
                      component={TeacherPasswordReset}
                    />
                    <GuestRoute
                      path={`${url}/thanks-reset-request`}
                      component={TeacherThanksResetRequest}
                    />
                    <GuestRoute
                      path={`${url}/password-reset-complete`}
                      component={TeacherPasswordResetComplete}
                    />
                    <Route
                      path={`${url}/mypage`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <PrivateRoute
                            exact
                            path={`${url}/settings`}
                            component={Settings}
                          />
                          <PrivateRoute
                            path={`${url}/account-deletion-request`}
                            component={AccountDeletionRequest}
                          />
                          <PrivateRoute
                            path={`${url}/account-deletion-request-complete`}
                            component={AccountDeletionRequestComplete}
                          />
                          <Route
                            path={`${url}/e-learnings`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <PrivateRoute
                                  exact
                                  path={`${url}/new`}
                                  component={ELearningNew}
                                />
                                <PrivateRoute
                                  exact
                                  path={`${url}/result`}
                                  component={ElearningResult}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/requests`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <PrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={TeacherRequestShow}
                                />
                                {/* useParamsで取るために記述変更 */}
                                <PrivateRoute
                                  path={`${url}/:id/agenda`}
                                  component={MeetingSheet}
                                />
                                <PrivateRoute
                                  path={`${url}/`}
                                  component={TeacherMyRequests}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/lessons`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <PrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={TeacherLessonShow}
                                />
                                {/* useParamsで取るために記述変更 */}
                                <PrivateRoute
                                  path={`${url}/:id/agenda`}
                                  component={MeetingSheet}
                                />
                                <PrivateRoute
                                  path={`${url}/:id/after-lesson-survey`}
                                  component={TeacherAfterLessonSurvey}
                                />
                                <PrivateRoute
                                  path={`${url}/:id/after-lesson-survey-complete`}
                                  component={TeacherSendedAfterLessonSurvey}
                                />
                                <PrivateRoute
                                  path={`${url}/`}
                                  component={TeacherMyLessons}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/recruitments`}
                            render={() => (
                              <PrivateRoute
                                path={`${url}/`}
                                component={TeacherMyRecruitments}
                              />
                            )}
                          />
                          <PrivateRoute
                            path={`${url}/`}
                            component={TeacherMypage}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route
                      path={`${url}/profile`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <PrivateRoute
                            path={`${url}/new`}
                            component={TeacherProfileNew}
                          />
                          <PrivateRoute
                            path={`${url}/edit`}
                            component={TeacherProfileEdit}
                          />
                          <PrivateRoute
                            path={`${url}/complete`}
                            component={TeacherProfileComplete}
                          />
                          <PrivateRoute
                            path={`${url}/to-new`}
                            component={TeacherProfileToNew}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route path={`${url}/:id`} component={TeacherShow} />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              {/* 複業先生向けRoute ここまで */}
              {/* 授業依頼用Route (URLに関しては要検討) */}
              <Route
                path="/requests"
                render={({ match: { url } }) => (
                  <Switch>
                    <SchoolPrivateRoute
                      path={`${url}/new`}
                      component={RequestsNew}
                    />
                    <SchoolPrivateRoute
                      path={`${url}/thanks-request`}
                      component={RequestsCreate}
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              {/* 授業依頼用Route ここまで */}
              {/* 学校用Route */}
              <Route
                path="/schools"
                render={({ match: { url } }) => (
                  <Switch>
                    <SchoolGuestRoute
                      path={`${url}/signup`}
                      component={SchoolSignup}
                    />
                    <SchoolGuestRoute
                      path={`${url}/login`}
                      component={SchoolLogin}
                    />
                    <SchoolGuestRoute
                      path={`${url}/thanks-signup`}
                      component={SchoolThanksSignup}
                    />
                    <SchoolGuestRoute
                      path={`${url}/forgot-password`}
                      component={SchoolForgotPassword}
                    />
                    <SchoolGuestRoute
                      path={`${url}/password-reset`}
                      component={SchoolPasswordReset}
                    />
                    <SchoolGuestRoute
                      path={`${url}/thanks-reset-request`}
                      component={SchoolThanksResetRequest}
                    />
                    <SchoolGuestRoute
                      path={`${url}/password-reset-complete`}
                      component={SchoolPasswordResetComplete}
                    />
                    <Route
                      path={`${url}/mypage`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <SchoolPrivateRoute
                            exact
                            path={`${url}/change-email`}
                            component={SchoolChangeEmail}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/change-password`}
                            component={ChangePassword}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/account-deletion-request`}
                            component={AccountDeletionRequest}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/account-deletion-request-complete`}
                            component={AccountDeletionRequestComplete}
                          />
                          <Route
                            path={`${url}/ai-teaching-plan`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/new`}
                                  component={AiTeachingPlanNew}
                                />
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/result`}
                                  component={AiTeachingPlanCreate}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/requests`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={SchoolRequestShow}
                                />
                                {/* useParamsで取るために記述変更 */}
                                <SchoolPrivateRoute
                                  path={`${url}/:id/agenda`}
                                  component={MeetingSheet}
                                />
                                <SchoolPrivateRoute
                                  path={`${url}/`}
                                  component={SchoolMyRequests}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/recruitments`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={RecruitmentShowForSchool}
                                />
                                <SchoolPrivateRoute
                                  path={`${url}/`}
                                  component={SchoolMyRecruitments}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <Route
                            path={`${url}/lessons`}
                            render={({ match: { url } }) => (
                              <Switch>
                                <SchoolPrivateRoute
                                  exact
                                  path={`${url}/:id`}
                                  component={SchoolLessonShow}
                                />
                                {/* useParamsで取るために記述変更 */}
                                <SchoolPrivateRoute
                                  path={`${url}/:id/agenda`}
                                  component={MeetingSheet}
                                />
                                <SchoolPrivateRoute
                                  path={`${url}/`}
                                  component={SchoolMyLessons}
                                />
                                <Route component={NotFound} />
                              </Switch>
                            )}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/favorite-teachers`}
                            component={FavoriteTeachersIndex}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/`}
                            component={SchoolMypage}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route
                      path={`${url}/profile`}
                      render={({ match: { url } }) => (
                        <Switch>
                          <SchoolPrivateRoute
                            path={`${url}/new`}
                            component={SchoolProfileNew}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/complete`}
                            component={SchoolProfileComplete}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/to-new`}
                            component={SchoolProfileToNew}
                          />
                          <SchoolPrivateRoute
                            path={`${url}/edit`}
                            component={SchoolProfileEdit}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      )}
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              {/* 授業募集要Route */}
              <Route
                path="/recruitments"
                render={({ match: { url } }) => (
                  <Switch>
                    <SchoolPrivateRoute
                      path={`${url}/new`}
                      component={RecruitmentNew}
                    />
                    <PrivateRoute
                      path={`${url}/:id`}
                      component={RecruitmentShow}
                    />
                    <Route component={RecruitmentIndex} />
                  </Switch>
                )}
              />
              {/* 生徒向けアンケート用Route */}
              <Route
                path="/lessons/:id"
                render={({ match: { path } }) => (
                  <Switch>
                    {/* MEMO: URLの関係からひらがな版を先に記述 */}
                    <Route
                      path={`${path}/student_before_questionnaires/new/hiragana`}
                      component={StudentBeforeQuestionnaireHiragana}
                    />
                    <Route
                      path={`${path}/student_before_questionnaires/complete/hiragana`}
                      component={SendedBeforeQuestionnairesHiragana}
                    />
                    <Route
                      path={`${path}/student_before_questionnaires/complete`}
                      component={SendedBeforeQuestionnaires}
                    />
                    <Route
                      path={`${path}/student_before_questionnaires/new`}
                      component={StudentBeforeQuestionnaire}
                    />
                    <Route
                      path={`${path}/student_after_questionnaires/new/hiragana`}
                      component={StudentAfterQuestionnaireHiragana}
                    />
                    <Route
                      path={`${path}/student_after_questionnaires/complete/hiragana`}
                      component={SendedAfterQuestionnairesHiragana}
                    />
                    <Route
                      path={`${path}/student_after_questionnaires/complete`}
                      component={SendedAfterQuestionnaires}
                    />
                    <Route
                      path={`${path}/student_after_questionnaires/new`}
                      component={StudentAfterQuestionnaire}
                    />
                    <Route component={NotFound} />
                  </Switch>
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
