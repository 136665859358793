import React from "react";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FindFavoriteTeacherImage from "assets/schools/mypage/FindFavoriteTeachers.png";
import Button from "components/atoms/mui/Button";

const FindFavoriteTeacherCard: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: "1rem",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "8px",
        border: `1px solid ${theme.palette.border?.main}`
      }}
    >
      <Box
        component="img"
        alt="お気に入りの先生を探しましょう"
        src={FindFavoriteTeacherImage}
        sx={{
          width: "120px"
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem"
          }}
        >
          <Typography variant="h3">
            授業をしてほしい先生は見つかりましたか？
          </Typography>
          <Typography>
            授業を依頼したい先生を、複業先生検索で探してみましょう！
          </Typography>
        </Box>
        <Button size="small" href="/teachers">
          複業先生を探してみる
        </Button>
      </Box>
    </Box>
  );
};

export default FindFavoriteTeacherCard;
