export const newProfileFormDefaultValues = {
  image: "",
  image_file_name: "profile",
  name: "",
  kana_name: "",
  phone_number: "",
  origin_prefecture_id: undefined,
  organization_name: "",
  birthday: "",
  introduction: "",
  enthusiasm: "",
  tag_ids: [],
  residence_prefecture_id: undefined,
  is_reward: true,
  can_interview: undefined,
  lesson_contents: "",
  know_from: undefined,
  origin_school_name: "",
  origin_high_school_name: "",
  origin_graduate_school_name: "",
  is_mail_magazine: true,
  websites: [
    {
      title: "",
      url: ""
    }
  ],
  is_limited_public: false
};
