import { Methods } from "api/api/v1/teacher_profiles";

export type StepType = {
  required: (keyof Methods["post"]["reqBody"])[];
  optional: (keyof Methods["post"]["reqBody"])[];
  title?: string;
};

const newProfileFormSteps: StepType[] = [
  {
    required: [
      "name",
      "kana_name",
      "phone_number",
      "birthday",
      "residence_prefecture_id",
      "origin_prefecture_id",
      "is_limited_public",
      "is_reward"
    ],
    optional: [],
    title: "基本情報の入力をお願いします"
  },
  {
    required: [
      "introduction",
      "tag_ids",
      "enthusiasm",
      "lesson_contents",
      "image",
      "is_mail_magazine",
      "know_from"
    ],
    optional: [],
    title: "プロフィールの作成をお願いします"
  },
  {
    required: [],
    optional: [
      "organization_name",
      "know_from",
      "origin_high_school_name",
      "origin_school_name",
      "origin_graduate_school_name",
      "license",
      "teaching_licenses",
      "can_interview"
    ],
    title: "その他の経歴を埋めることで選ばれやすくなります"
  }
];

export default newProfileFormSteps;
