import React, { ReactElement, JSXElementConstructor } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { SxProps } from "@mui/system";

import { usePageTabSlides } from "hooks/usePageTabSlides";

type CustomItemProps = {
  Icon?:
    | string
    | ReactElement<any, string | JSXElementConstructor<any>>
    | undefined;
  iconPosition?: "start" | "end";
  labelName: string;
  tabPanelContents: React.ReactNode;
  tabPanelSx?: SxProps;
};

type CustomTabSectionProps = {
  tabs: Array<CustomItemProps>;
  displayTabs?: boolean;
};

const CustomTabSection: React.FC<CustomTabSectionProps> = (props) => {
  const { tabs, displayTabs } = props;
  const { tabIndex, onSwiper, onSlideChange, onTabSlidesChange } =
    usePageTabSlides({ tabList: ["", "chat"] });
  const theme = useTheme();

  const defaultTabPanelSx: SxProps = {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    width: "100%"
  };

  // タブを表示しない場合、縦に要素を並べる
  if (!displayTabs) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem"
        }}
      >
        {tabs.map((tab, index) => (
          <Box
            key={index}
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={tab.tabPanelSx || defaultTabPanelSx}
          >
            {tab.tabPanelContents}
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <Tabs
        // MEMO: tab_nameがundefinedの場合に初期表示タブを見失うため、デフォルトを空文字で設定
        value={tabIndex}
        onChange={onTabSlidesChange}
        variant="fullWidth"
        sx={{
          display: displayTabs ? "flex" : "none",
          "& .MuiTabs-indicator": {
            height: "3px"
          }
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            value={index}
            icon={tab.Icon}
            iconPosition={tab.iconPosition || "start"}
            sx={{
              backgroundColor: theme.palette.border?.main,
              borderRadius: "8px 8px 0 0",
              border: `1px solid ${theme.palette.border?.main}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "48px",
              "&.Mui-selected": {
                color: theme.palette.primary.main,
                fontWeight: "bold",
                backgroundColor: theme.palette.common.white,
                fontSize: "1rem"
              }
            }}
            label={tab.labelName}
            key={tab.labelName}
          />
        ))}
      </Tabs>
      <Box
        component={Swiper}
        simulateTouch={false}
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
        sx={{ width: "100%" }}
      >
        {tabs.map((tab, index) => (
          <SwiperSlide key={index}>
            <Box
              key={index}
              role="tabpanel"
              hidden={tabIndex !== index}
              id={`tabpanel-${index}`}
              aria-labelledby={`tab-${index}`}
              sx={tab.tabPanelSx || defaultTabPanelSx}
            >
              {tab.tabPanelContents}
            </Box>
          </SwiperSlide>
        ))}
      </Box>
    </Box>
  );
};

export default CustomTabSection;
