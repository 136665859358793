import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom, useAtomValue } from "jotai";
import { useParams } from "react-router-dom";

import RequestButton from "features/teachers/id/atoms/RequestButton";
import PriceBox from "features/teachers/id/molecules/PriceBox";
import Meta from "components/organisms/Meta";
import CommonLayout from "components/layouts/CommonLayout";
import { isLoggedInAtom } from "store/AuthStore";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";

import useAlertToNew from "hooks/useAlertToNew";
import useQueryParams from "hooks/useQueryParams";
import useFavoriteTeachersApi from "hooks/useFavoriteTeachers";
import { teacherInfoAtom } from "./store";
import ProfileBox from "./organisms/ProfileBox";
import LessonInfoBox from "./organisms/LessonInfoBox";
import BasicInfoBox from "./organisms/BasicInfoBox";
import RatingLists from "./organisms/RatingLists";
import useShowPageApi from "./useApi";
import LeadingRegisterModal from "./molecules/LeadingRegisterModal";

type paramTypes = {
  id: string;
};

const Show: React.FC = () => {
  const [teacherInfo] = useAtom(teacherInfoAtom);
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  const theme = useTheme();
  const { id } = useParams<paramTypes>();
  const { isLoading } = useShowPageApi(id);
  const { createFavoriteTeacher, removeFavoriteTeacher } =
    useFavoriteTeachersApi();
  useAlertToNew();

  const { getQueryParams } = useQueryParams<{ role: string }>();
  const { role } = getQueryParams(["role"]);
  const isCandidateDisplay = role === "candidate_display";

  return (
    <CommonLayout isLoading={isLoading}>
      <Meta
        title={`複業先生|${teacherInfo?.name}`}
        description={`${teacherInfo?.name}|${teacherInfo?.introduction}`}
      />
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem 0",
          padding: { sx: 0, sm: "inherit" }
        }}
      >
        <BasicInfoBox
          createFavoriteTeacher={createFavoriteTeacher}
          removeFavoriteTeacher={removeFavoriteTeacher}
        />
      </Container>
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          padding: "1rem 0"
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem 0",
            padding: { sx: 0, sm: "inherit" }
          }}
        >
          <LessonInfoBox />
          <ProfileBox />
          <RatingLists />
        </Container>
        {!isCandidateDisplay && (
          <>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                padding: "1.2rem 1rem 0"
              }}
            >
              {schoolIsLoggedIn && <PriceBox />}
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                padding: "0 1rem 1.2rem",
                position: isLoggedIn ? "block" : "sticky",
                bottom: 0,
                width: "100%"
              }}
            >
              <RequestButton />
            </Box>
          </>
        )}
      </Box>
      <LeadingRegisterModal />
    </CommonLayout>
  );
};

export default Show;
