import * as yup from "yup";

export type afterQuestionnairesFormSchemaType = {
  schoolClass: string;
  studentIDNumber: string;
  learnWantedToKnow: string;
  isUseful: string;
  wantedToKnowMore: string;
  wantedToTell: string;
};

const afterQuestionnairesFormSchema: yup.ObjectSchema<afterQuestionnairesFormSchemaType> =
  yup.object().shape({
    schoolClass: yup.string().required("かならずこたえてください"),
    studentIDNumber: yup.string().required("かならずこたえてください"),
    learnWantedToKnow: yup.string().required("かならずこたえてください"),
    isUseful: yup.string().required("かならずこたえてください"),
    wantedToKnowMore: yup.string().required("かならずこたえてください"),
    wantedToTell: yup.string().required("かならずこたえてください")
  });

export default afterQuestionnairesFormSchema;
