import type React from "react";
import { useAtomValue } from "jotai";
import { Box } from "@mui/material";
import FormFacePhotoField from "components/molecules/FormFacePhotoField";
import FormTextArea from "components/molecules/FormTextArea";
import FormChipGroup from "components/molecules/FormChipGroup";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { knowFromOptions, mailMagazineOptions } from "constants/selectOptions";
import { tags } from "../store";
import StepTitle from "../atoms/StepTitle";
import IntroductionText from "../molecules/InctoductionText";

const NewProfileFormStep2: React.FC = () => {
  const tagsValue = useAtomValue(tags);
  const tagsOptions = tagsValue.map((tag) => ({
    value: tag.id,
    label: tag.name
  }));

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "0.5rem 0"
      }}
    >
      <StepTitle stepName={["プロフィールの作成をお願いします"]} />
      <FormTextArea
        name="introduction"
        label="講師紹介文"
        isRequired
        supplementalText={<IntroductionText />}
        placeholder="例：実績やバックグラウンドなど"
      />
      <FormChipGroup
        name="tag_ids"
        label="授業ができるテーマがあれば教えてください。（複数選択可）"
        options={tagsOptions}
        isRequired
        multiple
      />
      <FormTextArea
        name="enthusiasm"
        label="意気込みを一言で（50文字以内）"
        placeholder="例：自分たちが今生きている場所が全てではない、そこからさらに面白い世界や未来が広がっていることを伝えたい"
        isRequired
        maxCharacters={50}
      />
      <FormTextArea
        name="lesson_contents"
        label="こんな授業ができます（250文字以内）"
        placeholder="例：金融業務で学んできたお金の流れについてお話することができます。企業や個人がお金を借りたり貸したりするメカニズムや、銀行がどのように預金を使ってローンを提供するかなどを具体的な事例を用いて学びます。また、金融市場や投資の仕組みも触れ、経済の動きとお金の関係を理解することで、将来のキャリアや投資判断に役立てる知識を身につけることができます。"
        isRequired
        maxCharacters={250}
      />
      <FormFacePhotoField
        name="image"
        label="顔写真"
        isPublic
        isRequired
        supplementalText={
          <div>
            お顔がはっきり写っているご本人写真を登録してください。
            ご本人の確認できない写真の場合、審査を通過することができません。
            <br />
            ※お写真は1:1のものを推奨しております。
          </div>
        }
      />
      <FormRadioGroup
        name="is_mail_magazine"
        label="メールマガジンの配信"
        options={mailMagazineOptions}
        isRequired
        supplementalText="イベントなど、LX DESIGN（『複業先生』事務局）からのお知らせ・情報が届きます。『複業先生』プラットフォームから自動的に配信されるメールは停止されません。例＞授業募集　等"
      />
      <FormRadioGroup
        name="know_from"
        label="「複業先生」をどのように知りましたか？"
        options={knowFromOptions}
        isRequired
        row
      />
    </Box>
  );
};

export default NewProfileFormStep2;
