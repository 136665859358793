import * as yup from "yup";

import { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_teacher_questionnaires";

const afterLessonSurveyFormSchema: yup.ObjectSchema<
  Methods["post"]["reqBody"]
> = yup.object().shape({
  lesson_expectation_met: yup.number().required("必須の項目です"),
  message_delivered_to_student: yup.number().required("必須の項目です"),
  feedback_comments: yup.string().required("必須の項目です"),
  support_satisfaction: yup.number().required("必須の項目です"),
  support_satisfaction_reason: yup.string().required("必須の項目です"),
  publication_answer_id: yup.number().required("必須の項目です"),
  is_introduce_to_affiliation: yup.boolean().required("必須の項目です"),
  future_participation: yup.number().required("必須の項目です"),
  additional_comments: yup.string().optional()
});

export default afterLessonSurveyFormSchema;
