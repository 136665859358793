import { useEffect } from "react";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import dayjs from "libs/dayjs";

import useHeaders from "hooks/useHeaders";

import { useAlertMessages } from "hooks/useAlertMessages";
import { useHistory } from "react-router";
import { Methods } from "api/api/v1/teacher_profiles";
import {
  prefectures,
  tags,
  temporarilySavedProfileAtom,
  temporarilySavedAtAtom,
  lastNameAtom,
  firstNameAtom,
  lastNameKanaAtom,
  firstNameKanaAtom
} from "./store";

import newProfileFormSchema from "./constants/newProfileFormSchema";
import { newProfileFormDefaultValues } from "./constants/newProfileFormDefaultValues";

const useNewPageApi = () => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const [, setPrefectures] = useAtom(prefectures);
  const [, setTags] = useAtom(tags);
  const [temporarilySavedProfile, setTemporarilySavedProfile] = useAtom(
    temporarilySavedProfileAtom
  );
  const [, setTemporarilySavedAt] = useAtom(temporarilySavedAtAtom);
  const [, setLastName] = useAtom(lastNameAtom);
  const [, setFirstName] = useAtom(firstNameAtom);
  const [, setLastNameKana] = useAtom(lastNameKanaAtom);
  const [, setFirstNameKana] = useAtom(firstNameKanaAtom);
  const history = useHistory();

  const methods = useForm<Methods["post"]["reqBody"]>({
    mode: "onChange",
    resolver: yupResolver(newProfileFormSchema),
    defaultValues: temporarilySavedProfile || newProfileFormDefaultValues
  });

  const { data } = useAspidaSWR(aspida(headers).api.v1.teacher_profiles.new, {
    onSuccess: (data) => {
      setPrefectures(data.prefectures);
      setTags(data.tags);
    },
    onError: () => {
      addErrorMessage("データの取得に失敗しました");
      history.push("/teachers/login");
    }
  });

  const postNewProfile = async (params: Methods["post"]["reqBody"]) => {
    // websiteのtitleとurlが空の場合は除外する
    const webSites = params?.websites?.filter(
      (website) => website.title !== "" && website.url !== ""
    );
    params.websites = webSites;

    try {
      const response = await aspida(headers).api.v1.teacher_profiles.post({
        body: params
      });
      if (response.status === 200) {
        history.push("/teachers/profile/complete");
        setTemporarilySavedProfile(RESET);
        setTemporarilySavedAt(RESET);
        setLastName(RESET);
        setFirstName(RESET);
        setLastNameKana(RESET);
        setFirstNameKana(RESET);
      } else {
        addErrorMessage("データの送信に失敗しました");
      }
    } catch {
      addErrorMessage("データの送信に失敗しました");
    }
  };

  // MEMO: LocalStorageにプロフィールを一時保存する処理を追加
  const saveProfileTemporarily = () => {
    setTemporarilySavedProfile(methods.getValues());
    setTemporarilySavedAt(dayjs().format("YYYY/MM/DD HH:mm:ss"));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // MEMO: 1分ごとにAtomWithLocalStorageにプロフィールを保存する
      saveProfileTemporarily();
    }, 60000); // 60000ミリ秒ごとに実行

    return () => {
      clearInterval(intervalId);
    }; // コンポーネントのアンマウント時にインターバルをクリア
  }, [temporarilySavedProfile]);

  return { data, methods, postNewProfile, saveProfileTemporarily };
};

export default useNewPageApi;
