import { useAtomValue } from "jotai";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import type React from "react";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";
import type { favoriteTeachersTypes } from "types/favoriteTeachersTypes";
import TeacherListItemCard from "../molecules/TeacherListItemCard";

import { teachersDataAtom, keywordsAtom } from "../store";

import RecruitmentLeading from "../molecules/RecruitmentLeading";

const TeachersList: React.FC<favoriteTeachersTypes> = (props) => {
  const { createFavoriteTeacher, removeFavoriteTeacher } = props;
  const teachers = useAtomValue(teachersDataAtom);
  const keywords = useAtomValue(keywordsAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  return (
    <section>
      <Grid container>
        {teachers.length !== 0 ? (
          teachers.map((teacher) => (
            <TeacherListItemCard
              key={teacher.teacher_id}
              teacher={teacher}
              keywords={keywords}
              createFavoriteTeacher={createFavoriteTeacher}
              removeFavoriteTeacher={removeFavoriteTeacher}
            />
          ))
        ) : (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1.5rem"
              }}
            >
              <Typography align="center" fontWeight="bold">
                該当する先生がいません。
              </Typography>
              {schoolIsLoggedIn && <RecruitmentLeading />}
            </Box>
          </Grid>
        )}
      </Grid>
    </section>
  );
};

export default TeachersList;
