import React, { useState } from "react";
import { useAtomValue } from "jotai";

import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import LessonStatusLabel from "components/atoms/lessons/LessonStatusLabel";
import LessonRequestConfirmBlock from "components/molecules/lessons/LessonRequestConfirmBlock";
import GoBackButton from "components/atoms/lessons/GoBackButton";

import { formatDateStringWithDay } from "utils/date";
import CollapseControlButton from "components/atoms/lessons/CollapseControlButton";
import { detailsAtom, schoolTeacherAtom } from "../store";

const LessonInfoSection: React.FC = () => {
  const details = useAtomValue(detailsAtom);
  const schoolTeacher = useAtomValue(schoolTeacherAtom);
  const [seeAll, setSeeAll] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallViewport = useMediaQuery("(max-height:900px)");

  const displayExactStartAt =
    details?.exact_start_at && formatDateStringWithDay(details?.exact_start_at);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1.5rem",
        width: "100%"
      }}
    >
      {!isMobile && !isSmallViewport && (
        <Box>
          <GoBackButton />
        </Box>
      )}
      <Box component="section">
        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            gap: { xs: ".5rem", md: "1rem" },
            flexDirection: { xs: "column", md: "row" }
          }}
        >
          <LessonStatusLabel status={details?.step || ""} />
          <Typography variant="h2">{displayExactStartAt}</Typography>
          <CollapseControlButton seeAll={seeAll} setSeeAll={setSeeAll} />
        </Box>
        <Collapse in={seeAll} unmountOnExit>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              margin: "1rem auto",
              width: "100%"
            }}
          >
            {/* ここに要素を追加していく */}
            <LessonRequestConfirmBlock
              title="依頼元の学校と先生"
              value={
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Avatar
                    src={schoolTeacher?.image.url}
                    alt={schoolTeacher?.name}
                  />
                  <Box>
                    <Typography fontSize=".8rem" color={theme.palette.sub.main}>
                      {schoolTeacher?.school_name}
                    </Typography>
                    <Typography fontSize="1rem">
                      {schoolTeacher?.name}
                    </Typography>
                  </Box>
                </Box>
              }
            />
            <LessonRequestConfirmBlock
              title="学年・生徒数"
              value={
                details
                  ? `${details?.school_grade}(${details.student_number}人)`
                  : ""
              }
              oneLine
            />
            <LessonRequestConfirmBlock
              title="授業時間"
              value={details ? `${details?.school_minutes}分` : ""}
              oneLine
            />
            <LessonRequestConfirmBlock
              title="授業テーマ"
              value={details ? details.tag : ""}
              oneLine
            />
            <LessonRequestConfirmBlock
              title="話してほしいこと"
              value={details ? details.lesson_content : ""}
            />
            {details?.student_condition && (
              <LessonRequestConfirmBlock
                title="生徒の今の様子"
                value={details.student_condition}
              />
            )}
            {details?.issue && (
              <LessonRequestConfirmBlock
                title="今感じている課題"
                value={details.issue}
              />
            )}
            {details?.reward && (
              <LessonRequestConfirmBlock
                title="報酬"
                value={`${details.reward}円（税込み）`}
              />
            )}
          </Box>
        </Collapse>
      </Box>
    </Container>
  );
};

export default LessonInfoSection;
