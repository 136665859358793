import React from "react";
import { useAtomValue } from "jotai";

import dayjs from "libs/dayjs";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Link from "components/atoms/mui/Link";
import {
  detailsAtom,
  bankAccountAtom,
  isQuestionnaireAnsweredAtom
} from "../store";

import QueSheetLink from "../molecules/QreSheetLink";

const LessonCompletedBlock: React.FC = () => {
  const theme = useTheme();

  const details = useAtomValue(detailsAtom);
  const bankAccount = useAtomValue(bankAccountAtom);
  const isQuestionnaireAnswered = useAtomValue(isQuestionnaireAnsweredAtom);

  const transferTiming = dayjs().add(1, "month").endOf("month").format("M月");

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primaryBackground?.main,
        borderRadius: "8px",
        padding: "1.5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        boxSizing: "border-box"
      }}
    >
      <Typography variant="h2">授業お疲れ様でした！</Typography>
      {details?.reward !== 0 &&
        (bankAccount &&
        (bankAccount.id === 0 || !Object.keys(bankAccount).length) ? (
          <Typography sx={{ display: "flex" }}>
            報酬振込先の口座が登録されていません。
            <Link href="/teachers/mypage/settings?accordion=bank-account">
              アカウント設定
            </Link>
            から口座の登録をお願いします。
          </Typography>
        ) : (
          <>
            <Typography>
              報酬は以下の口座に、{transferTiming}末に振り込まれます。
            </Typography>
            <Typography fontSize="1.1rem" fontWeight="bold">
              {bankAccount?.financial_institution_name}***
              {bankAccount?.account_number_last_four}
            </Typography>
          </>
        ))}
      {!isQuestionnaireAnswered && <QueSheetLink />}
    </Box>
  );
};

export default LessonCompletedBlock;
