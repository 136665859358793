import React from "react";
import { useAtomValue } from "jotai";

import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { formatDateTimeStringWithDay } from "utils/date";
import LessonInfoItem from "../molecules/LessonInfoItem";

import { afterLessonSurveyDataAtom } from "../store";

const LessonInfoBlock: React.FC = () => {
  const theme = useTheme();
  const afterLessonSurveyData = useAtomValue(afterLessonSurveyDataAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        padding: "1.5rem"
      }}
    >
      <Typography variant="h2">授業の概要</Typography>
      <LessonInfoItem
        title="学校名"
        value={afterLessonSurveyData?.school_name}
      />
      <LessonInfoItem
        title="授業日時"
        value={
          afterLessonSurveyData?.exact_start_at &&
          formatDateTimeStringWithDay(afterLessonSurveyData.exact_start_at)
        }
      />
    </Box>
  );
};

export default LessonInfoBlock;
