import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtomValue } from "jotai";

import HasTeacherLabel from "features/teachers/id/atoms/HasTeacherLabel";
import RequestButton from "features/teachers/id/atoms/RequestButton";
import PriceBox from "features/teachers/id/molecules/PriceBox";
import RatingBox from "features/teachers/id/molecules/RatingBox";
import TeacherImageBox from "features/teachers/id/molecules/TeacherImageBox";
import FavoriteTeacherButton from "components/molecules/FavoriteTeacherButton";
import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import type React from "react";
import useQueryParams from "hooks/useQueryParams";
import { favoriteTeacherIdsAtom } from "store/FavoriteTeachersStore";

import type { favoriteTeachersTypes } from "types/favoriteTeachersTypes";
import { teacherInfoAtom, ratingAtom } from "../store";

type QueryParamsType = {
  role?: string;
};

const BasicInfoBox: React.FC<favoriteTeachersTypes> = (props) => {
  const { createFavoriteTeacher, removeFavoriteTeacher } = props;
  const theme = useTheme();
  const teacherInfo = useAtomValue(teacherInfoAtom);
  const rating = useAtomValue(ratingAtom);
  const favoriteTeacherIds = useAtomValue(favoriteTeacherIdsAtom);
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);
  const { getQueryParams } = useQueryParams<QueryParamsType>();
  const isCandidateDisplay =
    getQueryParams(["role"]).role === "candidate_display";

  const handleCreateFavoriteTeacher = () => {
    if (teacherInfo) {
      createFavoriteTeacher(teacherInfo.teacher_id);
    }
  };

  const handleRemoveFavoriteTeacher = () => {
    if (teacherInfo) {
      removeFavoriteTeacher(teacherInfo.teacher_id);
    }
  };

  return (
    <Box
      component="section"
      sx={{
        position: "relative",
        margin: { xs: "1rem 0", md: "2rem 0" },
        padding: { xs: "0 1rem", md: "inherit" }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: { xs: "0 auto .5rem", md: "inherit" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0 1.5rem",
            flexGrow: 1
          }}
        >
          <TeacherImageBox
            teacherName={teacherInfo?.name}
            teacherRank={teacherInfo?.teacher_rank}
            teacherImageUrl={teacherInfo?.image.url}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem 0"
            }}
          >
            <Typography color={theme.palette.sub.main}>
              {teacherInfo?.organization_name}
            </Typography>
            <Typography
              variant="h2"
              fontSize="1.5rem"
              sx={{ lineHeight: "1.5" }}
            >
              {teacherInfo?.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }
              }}
            >
              {teacherInfo?.teaching_licenses.length !== 0 && (
                <HasTeacherLabel labelText="教員免許あり" />
              )}
              {teacherInfo?.is_lesson_experience === true && (
                <HasTeacherLabel labelText="授業登壇経験あり" />
              )}
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                gap: "1rem"
              }}
            >
              {Object.keys(rating || {}).length !== 0 && (
                <RatingBox
                  totalRating={(rating?.total_rating || 0) / 10}
                  ratingCount={rating?.rating_count || 0}
                />
              )}
              <FavoriteTeacherButton
                isFavorite={
                  (teacherInfo &&
                    favoriteTeacherIds?.includes(teacherInfo?.teacher_id)) ||
                  false
                }
                handleCreateFavoriteTeacher={handleCreateFavoriteTeacher}
                handleRemoveFavoriteTeacher={handleRemoveFavoriteTeacher}
              />
            </Box>
          </Box>
        </Box>
        {!isCandidateDisplay && (
          <Box sx={{ display: { xs: "none", md: "block" }, width: "320px" }}>
            {schoolIsLoggedIn && <PriceBox />}
            <RequestButton />
          </Box>
        )}
      </Box>
      {Object.keys(rating || {}).length !== 0 && (
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <RatingBox
            totalRating={(rating?.total_rating || 0) / 10}
            ratingCount={rating?.rating_count || 0}
          />
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          right: "1rem",
          top: 0,
          display: { xs: "block", sm: "none" }
        }}
      >
        <FavoriteTeacherButton
          isFavorite={
            (teacherInfo &&
              favoriteTeacherIds?.includes(teacherInfo?.teacher_id)) ||
            false
          }
          handleCreateFavoriteTeacher={handleCreateFavoriteTeacher}
          handleRemoveFavoriteTeacher={handleRemoveFavoriteTeacher}
        />
      </Box>
    </Box>
  );
};

export default BasicInfoBox;
