import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { useAtom } from "jotai";
import { useParams, useHistory } from "react-router-dom";

import Loading from "components/atoms/Loading";
import PrepatationLeading from "features/requests/meetingSheet/molecules/PrepatationLeading";
import MeetingSheetLeading from "features/requests/meetingSheet/molecules/MeetingSheetLeading";
import Meta from "components/organisms/Meta";
import ClassContentsFields from "features/requests/meetingSheet/organisms/ClassContentsFields";
import Header from "features/requests/meetingSheet/organisms/Header";
import PreparationFields from "features/requests/meetingSheet/organisms/PreparationFields";
import RequestContents from "features/requests/meetingSheet/organisms/RequestContents";
import SuggestLessonPlan from "features/requests/meetingSheet/organisms/SuggestLessonPlan";
import TimelineSection from "features/requests/meetingSheet/organisms/TimelineSection";
import useMeetingSheet from "features/requests/meetingSheet/useApi";
import {
  requestObjectAtom,
  editAuthAtom,
  editUserNameWithPrefixAndSuffixAtom
} from "features/requests/meetingSheet/store";

import type React from "react";
import type { routerIdType } from "types/routerParamsType";
import { FormProvider } from "react-hook-form";
import FormSubmitButton from "components/molecules/FormSubmitButton";

const New: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams<routerIdType>();
  const [editAuth] = useAtom(editAuthAtom);
  const [editUserName] = useAtom(editUserNameWithPrefixAndSuffixAtom);
  const [requestObject] = useAtom(requestObjectAtom);

  const {
    putMeetingSheet,
    getIsLoading,
    form,
    addTimeline,
    removeTimeline,
    moveTimeline,
    timelines,
    generateTeachingPlan,
    generatingTeachingPlan
  } = useMeetingSheet(id);

  const handleSubmit = () => {
    putMeetingSheet();
    history.goBack();
  };

  const getButtonText = () => {
    if (editAuth?.is_after_lesson) {
      return "授業終了後は編集できません";
    }
    if (editAuth?.has_auth) {
      return "授業準備シートを保存する";
    }
    if (editAuth?.is_canceled) {
      return "授業はキャンセルされています";
    }
    return `シートは$${editUserName}が編集中です`;
  };

  return (
    <div id="layout-root">
      <Meta
        title="複業先生｜授業準備シート"
        description="シートを埋めながら、授業にむけた準備を進めましょう。"
      />
      <Header />
      <Box sx={theme.mixins.toolbar} />
      {!getIsLoading ? (
        <Box
          sx={{
            backgroundColor: theme.palette.backgroundColor?.main,
            pb: "1.5rem"
          }}
        >
          <Container maxWidth="md" sx={{ padding: { xs: 0, sm: "inherit" } }}>
            <FormProvider {...form}>
              <MeetingSheetLeading />
              <Box
                sx={{
                  margin: "1.5rem auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem"
                }}
                component="form"
                onSubmit={form.handleSubmit(handleSubmit)}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    padding: "1.5rem"
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ width: "100%", marginBottom: "1.5rem" }}
                  >
                    授業内容について
                  </Typography>
                  <RequestContents />
                  <SuggestLessonPlan
                    generateTeachingPlan={generateTeachingPlan}
                    isLoading={generatingTeachingPlan}
                  />
                </Box>
                <ClassContentsFields />
                <TimelineSection
                  addTimeline={addTimeline}
                  removeTimeline={removeTimeline}
                  moveTimeline={moveTimeline}
                  timelines={timelines}
                />
                <PreparationFields />
                <PrepatationLeading />
                <FormSubmitButton
                  label={getButtonText()}
                  disabled={!editAuth?.has_auth || editAuth?.is_after_lesson}
                  fullWidth
                />
              </Box>
            </FormProvider>
          </Container>
        </Box>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default New;
