import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import React from "react";

import Loading from "components/atoms/Loading";
import Meta from "components/organisms/Meta";
import CommonPagePagination from "components/organisms/CommonPagePagination";
import CommonLayout from "components/layouts/CommonLayout";
import useAlertToNew from "hooks/useAlertToNew";
import { FormProvider } from "react-hook-form";
import useFavoriteTeachersApi from "hooks/useFavoriteTeachers";
import ToSupportBannerBottom from "./organisms/ToSupportBannerBottom";
import FirstView from "./organisms/FirstView";
import SearchForm from "./organisms/SearchForm";
import SortSection from "./organisms/SortSection";
import TeachersCount from "./organisms/TeachersCount";
import TeachersList from "./organisms/TeachersList";

import useIndexPageApi, { getIndexType } from "./useApi";

const Index: React.FC = () => {
  useAlertToNew();
  const theme = useTheme();

  const { search, reset, isLoading, methods } = useIndexPageApi();
  // MEMO: 一旦ここのloadingは待たない
  const { createFavoriteTeacher, removeFavoriteTeacher } =
    useFavoriteTeachersApi();

  const handleSearch = async (data: getIndexType) => {
    search(data);
  };

  return (
    <CommonLayout>
      <Meta
        title="複業先生一覧"
        description="学校で授業を行うことのできる複業先生の一覧です。様々な経歴の方に授業依頼を依頼をすることができます。"
      />
      <FirstView />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main
        }}
      >
        <Container
          sx={{
            padding: "1.5rem 0"
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSearch)}>
              <SearchForm reset={reset} />
            </form>
          </FormProvider>
          <SortSection />
          {!isLoading ? (
            <>
              <TeachersCount />
              <TeachersList
                createFavoriteTeacher={createFavoriteTeacher}
                removeFavoriteTeacher={removeFavoriteTeacher}
              />
              <CommonPagePagination />
            </>
          ) : (
            <Loading />
          )}
        </Container>
        <ToSupportBannerBottom />
      </Box>
    </CommonLayout>
  );
};

export default Index;
