import * as yup from "yup";

import { Methods } from "api/api/v1/lessons/_lessonId@string";

export type updateLessonFormSchemaType = Methods["patch"]["reqBody"];

export const updateLessonFormSchemaBase: yup.ObjectSchema<updateLessonFormSchemaType> =
  yup.object().shape({
    meeting_url: yup.string().optional(),
    meeting_place: yup.string().optional(),
    meeting_start_at: yup.string().optional(),
    is_meeting_online: yup.boolean().optional(),
    exact_start_at: yup.string().optional(),
    lesson_url: yup.string().optional(),
    lesson_report_url: yup.string().optional(),
    lesson_completed_at: yup.string().optional(),
    is_skip_meeting: yup.boolean().optional(),
    is_skip_generate_report: yup.boolean().optional(),
    lesson_preliminary_report_completed_at: yup.string().optional(),
    is_online: yup.boolean().optional(),
    place: yup.string().optional(),
    is_skip_lesson_preliminary_report: yup.boolean().optional(),
    is_skip_after_lesson_report: yup.boolean().optional(),
    after_lesson_report_completed_at: yup.string().optional(),
    is_plain_questionnaires: yup.boolean().optional()
  });
