import React from "react";
import { useAtomValue } from "jotai";

import { useMediaQuery } from "@mui/material";

import FavoriteButton from "components/atoms/FavoriteButton";
import FavoriteButtonWithMessage from "components/atoms/FavoriteButtonWithMessage";

import { schoolIsLoggedInAtom } from "store/SchoolAuthStore";

type FavoriteTeacherButtonProps = {
  isFavorite: boolean;
  handleCreateFavoriteTeacher: () => void;
  handleRemoveFavoriteTeacher: () => void;
};

const FavoriteTeacherButton: React.FC<FavoriteTeacherButtonProps> = (props) => {
  const {
    isFavorite,
    handleCreateFavoriteTeacher,
    handleRemoveFavoriteTeacher
  } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");
  const schoolIsLoggedIn = useAtomValue(schoolIsLoggedInAtom);

  if (!schoolIsLoggedIn) {
    return null;
  }

  if (isMobile) {
    return (
      <FavoriteButton
        selected={isFavorite}
        createFavorite={handleCreateFavoriteTeacher}
        removeFavorite={handleRemoveFavoriteTeacher}
      />
    );
  }

  return (
    <FavoriteButtonWithMessage
      selected={isFavorite}
      createFavorite={handleCreateFavoriteTeacher}
      removeFavorite={handleRemoveFavoriteTeacher}
    />
  );
};

export default FavoriteTeacherButton;
