import { useState } from "react";
import type { Swiper as SwiperCore } from "swiper";
import useQueryParams from "./useQueryParams";

type PageTabSlidesProps = {
  tabList: Array<string>;
};

type QueryParamsType = {
  tab_name?: string;
};

// MEMO: page単位でtabを切り替える際に使用するhooks
export const usePageTabSlides = (props: PageTabSlidesProps) => {
  // MEMO: 複数ファイルで使おうとするとAtomに押し込まなくては行けなくなるため注意
  const { tabList } = props;
  const { getQueryParams, deleteQueryParams } =
    useQueryParams<QueryParamsType>();
  const { tab_name } = getQueryParams(["tab_name"]);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [tabIndex, setTabIndex] = useState(
    tab_name ? tabList.indexOf(tab_name) : 0
  );

  const onTabSlidesChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    swiper?.slideTo(newValue);
  };

  const onSwiper = (currentSwiper: SwiperCore) => {
    const swiperInstance = currentSwiper;
    setSwiper(swiperInstance);

    swiperInstance.slideTo(tabIndex);
  };

  const onSlideChange = (currentSwiper: SwiperCore) => {
    setTabIndex(currentSwiper.activeIndex);
  };

  const resetTabSlides = () => {
    setTabIndex(0);
    swiper?.slideTo(0);
    deleteQueryParams("tab_name");
  };

  return {
    tabIndex,
    onTabSlidesChange,
    onSwiper,
    onSlideChange,
    resetTabSlides
  };
};
