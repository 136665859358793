import React, { useState } from "react";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useTheme } from "@mui/material";

import Button from "./mui/Button";

type FavoriteButtonWithMessageProps = {
  selected?: boolean;
  message?: {
    selected: string;
    unselected: string;
  };
  createFavorite?: () => void;
  removeFavorite?: () => void;
};

const FavoriteButtonWithMessage: React.FC<FavoriteButtonWithMessageProps> = (
  props
) => {
  const { selected, message, createFavorite, removeFavorite } = props;
  const [isFavorite, setIsFavorite] = useState(selected);

  const theme = useTheme();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    // MEMO: 親コンポーネントにonClickイベントが用意されている場合には、イベントの伝播を止める
    event?.stopPropagation();
    if (isFavorite) {
      removeFavorite && removeFavorite();
    } else {
      createFavorite && createFavorite();
    }
    setIsFavorite(!isFavorite);
  };

  const displayMessage = message || {
    selected: "お気に入り",
    unselected: "お気に入り登録"
  };

  return (
    <Button
      onClick={(event) => handleClick(event)}
      color="inherit"
      variant="outlined"
      startIcon={
        isFavorite ? (
          <FavoriteIcon sx={{ color: theme.palette.favorite.main }} />
        ) : (
          <FavoriteBorderIcon />
        )
      }
      size="small"
      sx={{
        borderColor: theme.palette.border?.main,
        padding: "6px 12px"
      }}
    >
      {isFavorite ? displayMessage.selected : displayMessage.unselected}
    </Button>
  );
};

export default FavoriteButtonWithMessage;
