import React from "react";
import { useAtomValue } from "jotai";

import Box from "@mui/material/Box";

import CommonLoading from "components/atoms/CommonLoading";
import { favoriteTeachersAtom } from "store/FavoriteTeachersStore";
import type { favoriteTeachersTypes } from "types/favoriteTeachersTypes";
import TeacherListItemCard from "../molecules/TeacherListItemCard";

import FindFavoriteTeacherCard from "../molecules/FindFavoriteTeacherCard";

type TeachersListProps = {
  isLoading?: boolean;
} & favoriteTeachersTypes;

const TeachersList: React.FC<TeachersListProps> = (props) => {
  const { isLoading, createFavoriteTeacher, removeFavoriteTeacher } = props;
  const favoriteTeachers = useAtomValue(favoriteTeachersAtom);

  return (
    <Box>
      {!isLoading ? (
        favoriteTeachers && favoriteTeachers.length > 0 ? (
          favoriteTeachers?.map((teacher) => (
            <TeacherListItemCard
              key={teacher.teacher_id}
              teacher={teacher}
              createFavoriteTeacher={createFavoriteTeacher}
              removeFavoriteTeacher={removeFavoriteTeacher}
            />
          ))
        ) : (
          <FindFavoriteTeacherCard />
        )
      ) : (
        <CommonLoading />
      )}
    </Box>
  );
};

export default TeachersList;
