import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useAtomValue } from "jotai";

import dayjs from "libs/dayjs";

import type React from "react";

import Button from "components/atoms/mui/Button";
import { formatDateStringWithDay } from "utils/date";
import { detailsAtom } from "../store";

const QreSheetLink: React.FC = () => {
  const details = useAtomValue(detailsAtom);

  const deadlineFormat = details?.lesson_completed_at
    ? formatDateStringWithDay(dayjs(details.lesson_completed_at).add(1, "week"))
    : "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "flex-end"
      }}
    >
      <Typography sx={{ width: "100%" }}>
        最後に、今回の授業についてのアンケートにご協力ください
      </Typography>
      {details?.lesson_completed_at && (
        <Typography fontSize="1rem" fontWeight="bold" sx={{ width: "100%" }}>
          期限：{deadlineFormat}まで
        </Typography>
      )}
      <Box>
        <Box
          component="a"
          target="_blank"
          href={`/teachers/mypage/lessons/${details?.id}/after-lesson-survey`}
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <Button>回答する</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QreSheetLink;
