export const schoolClassesOptions = [
  { label: "1くみ", value: "1組" },
  { label: "2くみ", value: "2組" },
  { label: "3くみ", value: "3組" },
  { label: "4くみ", value: "4組" },
  { label: "5くみ", value: "5組" },
  { label: "6くみ", value: "6組" },
  { label: "7くみ", value: "7組" },
  { label: "8くみ", value: "8組" },
  { label: "9くみ", value: "9組" },
  { label: "10くみ", value: "10組" },
  { label: "そのた①", value: "その他①" },
  { label: "そのた②", value: "その他②" },
  { label: "そのた③", value: "その他③" }
];

export const isSurveyOptions = [
  { label: "しらべた", value: "調べた" },
  { label: "しらべていない", value: "特に調べていない" }
];

export const howMuchfunOptions = [
  { label: "たのしみにしていた", value: "楽しみにしていた" },
  { label: "すこしたのしみにしていた", value: "少し楽しみにしていた" },
  { label: "どちらでもない", value: "どちらでもない" },
  {
    label: "あまりたのしみにしていなかった",
    value: "あまり楽しみにしていなかった"
  },
  { label: "たのしみにしていなかった", value: "楽しみにしていなかった" }
];

export const studentIDNumberOptions = () => {
  const options = [];
  for (let index = 1; index < 50; index++) {
    options.push({ label: index.toString(), value: index.toString() });
  }
  options.push({ label: "51いじょう", value: "51以上" });

  return options;
};

export const learnWantedToKnowOptions = [
  { label: "しることができた", value: "知ることができた" },
  { label: "すこししることができた", value: "少し知ることができた" },
  { label: "どちらでもない", value: "どちらともいえない" },
  {
    label: "あまりしることができなかった",
    value: "あまり知ることができなかった"
  },
  { label: "しることができなかった", value: "知ることができなかった" }
];

export const isUsefulOptions = [
  { label: "やくにたちそうだとおもった", value: "役に立ちそうだと思った" },
  {
    label: "すこしやくにたちそうだとおもった",
    value: "少し役に立ちそうだと思った"
  },
  { label: "どちらでもない", value: "どちらともいえない" },
  {
    label: "あまりやくにたちそうだとおもわなかった",
    value: "あまり役に立ちそうだと思わなかった"
  },
  {
    label: "やくにたちそうだとおもわなかった",
    value: "役に立ちそうだと思わなかった"
  }
];
