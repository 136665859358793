import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useHeaders from "hooks/useHeaders";
import { useAlertMessages } from "hooks/useAlertMessages";

import { Methods } from "api/api/v1/lessons/_lessonId@string/lesson_teacher_questionnaires";
import { afterLessonSurveyDataAtom } from "./store";
import { afterLessonSurveyFormDefaultValues } from "./constants/afterLessonSurveyFormDefaultValues";
import afterLessonSurveyFormSchema from "./constants/afterLessonSurveyFormSchema";

const useAfterLessonSurveyApi = (lessonId: string) => {
  const headers = useHeaders();
  const { addErrorMessage } = useAlertMessages();
  const history = useHistory();
  const [, setAfterLessonSurveyData] = useAtom(afterLessonSurveyDataAtom);

  const methods = useForm<Methods["post"]["reqBody"]>({
    resolver: yupResolver(afterLessonSurveyFormSchema)
  });

  const { isLoading } = useAspidaSWR(
    aspida(headers).api.v1.lessons._lessonId_string(lessonId)
      .lesson_teacher_questionnaires.new,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
      onSuccess: (data) => {
        setAfterLessonSurveyData(data);
      },
      onError: (error) => {
        addErrorMessage("アンケート用データの取得に失敗しました");
      }
    }
  );

  const postAfterLessonSurvey = async () => {
    try {
      await aspida(headers)
        .api.v1.lessons._lessonId_string(lessonId)
        .lesson_teacher_questionnaires.post({
          body: methods.getValues()
        });
      history.push(`after-lesson-survey-complete`);
    } catch (error) {
      addErrorMessage("アンケートの送信に失敗しました");
    }
  };

  return {
    methods,
    postAfterLessonSurvey,
    isLoading
  };
};

export default useAfterLessonSurveyApi;
