import React from "react";

import Typography from "@mui/material/Typography";

import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Meta from "components/organisms/Meta";
import Box from "@mui/material/Box";
import Button from "components/atoms/mui/Button";
import ThanksAnswerBlock from "./organisms/ThanksAnswerBlock";

const SendedAfterLessonSurvey: React.FC = () => (
  <QuestionnairesLayout title="授業後アンケート">
    <Meta
      title="複業先生 | 授業後アンケートへの回答完了"
      description="お疲れ様でした。この授業についてのアンケートは正常に送信されました。"
    />
    <ThanksAnswerBlock
      title="アンケートへの回答、ありがとうございました！"
      descriptions={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            alignItems: "center"
          }}
        >
          <Typography textAlign="center">
            <Typography textAlign="center">
              アンケートは正常に送信されました。
            </Typography>
            <Typography textAlign="center">
              今後とも複業先生をよろしくおねがいします！
            </Typography>
          </Typography>
          <Button href="/teachers/mypage">マイページに戻る</Button>
        </Box>
      }
    />
  </QuestionnairesLayout>
);

export default SendedAfterLessonSurvey;
