import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import FormSelect from "components/molecules/FormSelect";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import {
  schoolClassesOptions,
  studentIDNumberOptions,
  learnWantedToKnowOptions,
  isUsefulOptions
} from "features/lessons/id/studentQuestionnaires/new/constants/hiragana/questionnairesOptions";
import afterQuestionnairesFormSchema, {
  afterQuestionnairesFormSchemaType
} from "../../constants/hiragana/afterQuestionnairesFormSchema";

type AfterQuestionnairesFormProps = {
  submitForm: (data: afterQuestionnairesFormSchemaType) => Promise<void>;
  disabled?: boolean;
};

const AfterQuestionnairesForm: React.FC<AfterQuestionnairesFormProps> = (
  props
) => {
  const { submitForm, disabled } = props;
  const theme = useTheme();

  const methods = useForm({
    resolver: yupResolver(afterQuestionnairesFormSchema)
  });

  const handleSubmit = methods.handleSubmit(
    async (data: afterQuestionnairesFormSchemaType) => {
      await submitForm(data);
    }
  );

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <Typography variant="h2">アンケートにこたえてください</Typography>
          <FormSelect
            name="schoolClass"
            label="クラスをおしえてください。（ないときはせんせいにそうだんしてください）"
            options={schoolClassesOptions}
            isRequired
            fullWidth
            disabled={disabled}
          />
          <FormSelect
            name="studentIDNumber"
            label="ばんごうをおしえてください（ないときはせんせいにそうだんしてください）"
            isRequired
            options={studentIDNumberOptions()}
            fullWidth
            disabled={disabled}
          />
          <FormRadioGroup
            name="learnWantedToKnow"
            label="じゅぎょうをきいて、しりたかったことをしることができましたか？"
            options={learnWantedToKnowOptions}
            isRequired
            row
            disabled={disabled}
          />
          <FormRadioGroup
            name="isUseful"
            label="じゅぎょうを聞いて、どれくらいやくにたちそうだとおもいましたか？"
            options={isUsefulOptions}
            isRequired
            row
            disabled={disabled}
          />
          <FormTextArea
            name="wantedToKnowMore"
            label="じゅぎょうをきいて、きになったこと・もっとしりたいとおもったことはありますか？"
            placeholder="例）〇〇のことがきになった、〇〇についてもっとしりたいなど"
            isRequired
            disabled={disabled}
          />
          <FormTextArea
            name="wantedToTell"
            label="じゅぎょうでわかったことをほかのひとにはなすとしたら、なにをはなしますか？"
            placeholder="例）〇〇のことがおもしろかった、〇〇のことをしってほしいなど"
            isRequired
            disabled={disabled}
          />
          {/* MEMO: このような書き方をしないと、disabledが渡ってくるフォームについて、バリデーションによるdisabledが効かない */}
          <FormSubmitButton
            label="アンケートをだす"
            {...(disabled ? { disabled } : {})}
          />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AfterQuestionnairesForm;
