import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Methods as NewMethods } from "api/api/v1/teacher_profiles/new";
import { Methods as CreateMethods } from "api/api/v1/teacher_profiles";

type PrefectureType = NewMethods["get"]["resBody"]["prefectures"][number];

type TagType = NewMethods["get"]["resBody"]["tags"][number];

export const prefectures = atom<PrefectureType[]>([]);
export const tags = atom<TagType[]>([]);

// MEMO: LocalStorageへのプロフィール一時保存用処理を追加
export const temporarilySavedProfileAtom = atomWithStorage<
  CreateMethods["post"]["reqBody"] | null
>("temporarilySavedProfile", null, undefined, {
  getOnInit: true
});
export const temporarilySavedAtAtom = atomWithStorage<string | null>(
  "temporarySavedAt",
  null,
  undefined,
  {
    getOnInit: true
  }
);

// MEMO: 本名登録部分はAPIの方とフロントのフォームで別々であるため、一時保存用のatomを追加
export const lastNameAtom = atomWithStorage<string | null>(
  "lastName",
  null,
  undefined,
  {
    getOnInit: true
  }
);
export const firstNameAtom = atomWithStorage<string | null>(
  "firstName",
  null,
  undefined,
  {
    getOnInit: true
  }
);
export const lastNameKanaAtom = atomWithStorage<string | null>(
  "lastNameKana",
  null,
  undefined,
  {
    getOnInit: true
  }
);
export const firstNameKanaAtom = atomWithStorage<string | null>(
  "firstNameKana",
  null,
  undefined,
  {
    getOnInit: true
  }
);

// MEMO: どうせstoreと密結合されるコンポーネントになるため、一時保存のデータのバリデーションもstoreに押し込み、jotaiライクに使う
export const lastNameIsErrorAtom = atom<boolean>((get) => {
  const lastName = get(lastNameAtom);
  if (lastName === null) {
    return false;
  }
  return lastName === "";
});
export const firstNameIsErrorAtom = atom<boolean>((get) => {
  const firstName = get(firstNameAtom);
  if (firstName === null) {
    return false;
  }
  return firstName === "";
});
export const lastNameKanaIsErrorAtom = atom<boolean>((get) => {
  const lastNameKana = get(lastNameKanaAtom);
  if (lastNameKana === null) {
    return false;
  }
  const KanaRegex = /^[ァ-ンヴー]*$/;
  return lastNameKana === "" || !KanaRegex.test(lastNameKana);
});
export const firstNameKanaIsErrorAtom = atom<boolean>((get) => {
  const firstNameKana = get(firstNameKanaAtom);
  if (firstNameKana === null) {
    return false;
  }
  const KanaRegex = /^[ァ-ンヴー]*$/;
  return firstNameKana === "" || !KanaRegex.test(firstNameKana);
});
