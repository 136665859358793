import Typography from "@mui/material/Typography";

import type React from "react";
import { Box } from "@mui/material";
import theme from "themes/theme";
import { useAtomValue } from "jotai";
import { useFormContext } from "react-hook-form";
import Chip from "components/atoms/mui/Chip";
import { isTrue } from "utils/checkBool";
import { knowFromOptions } from "constants/selectOptions";
import ConfirmField from "../molecules/ConfirmField";
import { tags as tagsAtom } from "../store";

const ConfirmStep02: React.FC = () => {
  const { getValues } = useFormContext();
  const imageSrc = getValues("image") || "";

  const tags = useAtomValue(tagsAtom);
  const tagIds = getValues("tag_ids");
  const tagsValue = (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem"
      }}
    >
      {tagIds?.map((id: number) => {
        const name = tags.find((tag) => tag.id === id)?.name;
        return <Chip key={id} label={name} />;
      })}
    </Box>
  );

  return (
    <Box
      component="section"
      sx={{
        padding: "1rem 1rem .5rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px"
      }}
    >
      <Typography variant="h2">プロフィール</Typography>
      <ConfirmField
        title="講師紹介文"
        value={getValues("introduction") || "未記入"}
      />
      <ConfirmField
        title="授業ができるテーマがあれば教えてください。"
        value={tagsValue}
      />
      <ConfirmField
        title="意気込み"
        value={getValues("enthusiasm") || "未記入"}
      />
      <ConfirmField
        title="こんな授業ができます"
        value={getValues("lesson_contents")}
      />
      <ConfirmField
        title="顔写真"
        value={<img src={imageSrc} alt="登録予定の顔写真" />}
      />
      <ConfirmField
        title="メルマガ配信"
        value={
          isTrue(getValues("is_mail_magazine")) ? "許可する" : "許可しない"
        }
      />
      <ConfirmField
        title="「複業先生」をどのように知りましたか？"
        value={
          knowFromOptions.find(
            (option) =>
              option.value.toString() === getValues("know_from")?.toString()
          )?.label || "未記入"
        }
      />
    </Box>
  );
};

export default ConfirmStep02;
