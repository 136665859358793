import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

// MEMO: 保存場所要検討
import RatingDisplay from "features/teachers/id/atoms/RatingDisplay";

import type React from "react";

type RatingBoxType = {
  totalRating: number;
  ratingCount: number;
};

const RatingBox: React.FC<RatingBoxType> = (props) => {
  const { totalRating, ratingCount } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0 .75rem",
        padding: ".25rem 1.2rem",
        backgroundColor: theme.palette.backgroundColor?.main,
        // FIXME: importantをつけないと、どこからかborderRadiusが16pxで上書きされてしまう
        borderRadius: "4px !important",
        margin: { xs: ".5rem auto", md: 0 }
      }}
    >
      <RatingDisplay rating={totalRating} />
      <Typography fontSize="1.1rem" fontWeight="bold">
        {totalRating}
      </Typography>
      <Typography color={theme.palette.sub.main}>
        評価：{ratingCount}件
      </Typography>
    </Box>
  );
};

export default RatingBox;
