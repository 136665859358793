import React from "react";

import Typography from "@mui/material/Typography";

import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Meta from "components/organisms/Meta";
import ThanksAnswerBlock from "../../../studentQuestionnaires/complete/organisms/ThanksAnswerBlock";

const SendedBeforeQuestionnaires: React.FC = () => (
  <QuestionnairesLayout title="じゅぎょうまえアンケート">
    <Meta
      title="じゅぎょうまえアンケートへのかいとうができました"
      description="このあとのじゅぎょうについてのアンケートはきちんとおくれました。"
    />
    <ThanksAnswerBlock
      title="アンケート、おつかれさまでした！"
      descriptions={
        <Typography textAlign="center">
          <Typography textAlign="center">
            アンケートをせんせいにだしました。
          </Typography>
          <Typography textAlign="center">
            ぜひたのしくじゅぎょうをうけてください！
          </Typography>
        </Typography>
      }
    />
  </QuestionnairesLayout>
);

export default SendedBeforeQuestionnaires;
