import React from "react";
import { useParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import dayjs from "libs/dayjs";

import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Meta from "components/organisms/Meta";
import Loading from "components/atoms/Loading";

import useQueryParams from "hooks/useQueryParams";

import LessonInfoBlock from "../../studentQuestionnaires/new/organisms/LessonInfoBlock";
import AfterQuestionnairesForm from "./organisms/AfterQuestionnairesForm";
import useQuestionnairesApi from "./useApi";
import { studentQuestionnairesDataAtom } from "../../studentQuestionnaires/new/store";

type paramsTypes = {
  id: string;
};

type QueryParamsType = {
  unique_id?: string;
};

const After: React.FC = () => {
  const { id } = useParams<paramsTypes>();
  const { getQueryParams } = useQueryParams<QueryParamsType>();

  const { unique_id } = getQueryParams(["unique_id"]);

  const { isLoading, submitAfterQuestionnaireForm } = useQuestionnairesApi(
    id,
    unique_id || ""
  );

  const studentQuestionnairesData = useAtomValue(studentQuestionnairesDataAtom);

  const isAfterLesson = dayjs().isAfter(
    dayjs(studentQuestionnairesData?.exact_start_at)
  );
  const isExpired = dayjs().isAfter(
    dayjs(studentQuestionnairesData?.exact_start_at).add(14, "day")
  );

  return (
    <QuestionnairesLayout title="授業後アンケート">
      <Meta
        title="複業先生 | 授業後アンケート"
        description="皆さんに受けてもらった授業についてのアンケートです。"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          {!isAfterLesson && (
            <Alert severity="error">
              このアンケートは授業終了後に回答してください
            </Alert>
          )}
          {isExpired && (
            <Alert severity="error">
              このアンケートは回答期限が過ぎています
            </Alert>
          )}
          <LessonInfoBlock />
          <AfterQuestionnairesForm
            submitForm={submitAfterQuestionnaireForm}
            disabled={!isAfterLesson || isExpired}
          />
        </Container>
      )}
    </QuestionnairesLayout>
  );
};

export default After;
