import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Box from "@mui/material/Box";

import BottomButtonGroup from "components/molecules/BottomButtonGroup";
import Meta from "components/organisms/Meta";
import AuthLayout from "components/layouts/AuthLayout";

import type React from "react";
import { FormProvider } from "react-hook-form";
import { Methods } from "api/api/v1/teacher_profiles";
import { useMemo, useState } from "react";
import theme from "themes/theme";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "store/AuthStore";
import newProfileFormSteps from "./constants/newProfileFormSteps";
import useNewPageApi from "./useApi";
import NewProfileFormStep3 from "./organisms/NewProfileFormStep3";
import NewProfileFormStep2 from "./organisms/NewProfileFormStep2";
import NewProfileFormStep1 from "./organisms/NewProfileFormStep1";
import NewProfileFormConfirm from "./organisms/NewProfileFormConfirm";
import TemporarilySaveBlock from "./organisms/TemporarilySaveBlock";
import SetupEyeCatch from "./atoms/SetupEyeCatch";

const New: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const currentUser = useAtomValue(currentUserAtom);
  const teacherId = Number(currentUser.id) || 0;

  const { postNewProfile, saveProfileTemporarily, methods } = useNewPageApi();
  const handleNext = () => {
    if (activeStep === 3) {
      methods.trigger();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTimeout(() => {
      window.scrollTo({
        top: 0
      });
    }, 500);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTimeout(() => {
      window.scrollTo({
        top: 0
      });
    }, 500);
  };

  const onSubmit = async () => {
    await postNewProfile(methods.getValues());
  };

  const isNextClickable = useMemo(() => {
    if (activeStep === 3) return true;
    // requiredのフィールドには全て値が入っている (初期値だとformStateのエラーでは検知できないため)
    const properties = newProfileFormSteps[activeStep];
    if (!properties) return false;
    const requiredFields = properties.required as Partial<
      keyof Methods["post"]["reqBody"]
    >[];
    // optionalを含めた全てのフィールドにエラーがない
    const allFields = [...properties.optional, ...requiredFields] as Partial<
      keyof Methods["post"]["reqBody"]
    >[];

    return (
      requiredFields.every(
        (field) =>
          methods.getValues(field) || methods.getValues(field) === false
      ) && allFields.every((field) => !methods.formState.errors[field])
    );
  }, [activeStep, methods.watch(), methods.formState.errors]);

  return (
    <AuthLayout>
      <Meta title="複業先生｜本登録" />
      <SetupEyeCatch />
      <Box
        sx={{
          backgroundColor:
            activeStep === 3
              ? theme.palette.backgroundColor?.main
              : theme.palette.common.white
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container maxWidth="sm">
              <Grid
                container
                sx={{
                  padding: "16px 0"
                }}
              >
                <Grid item xs={12}>
                  {/* ABテストのため、teacherIdが奇数の場合のみステッパーを表示する */}
                  {teacherId % 2 === 1 && (
                    <Stepper activeStep={activeStep}>
                      {newProfileFormSteps.map((step) => (
                        <Step key={step.title}>
                          <StepLabel />
                        </Step>
                      ))}
                    </Stepper>
                  )}
                  <TemporarilySaveBlock
                    saveProfileTemporarily={saveProfileTemporarily}
                    isDirty={methods.formState.isDirty}
                    values={methods.getValues()}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: activeStep === 0 ? "block" : "none" }}
                >
                  <NewProfileFormStep1 />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: activeStep === 1 ? "block" : "none" }}
                >
                  <NewProfileFormStep2 />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: activeStep === 2 ? "block" : "none" }}
                >
                  <NewProfileFormStep3 />
                </Grid>
                {activeStep === 3 && (
                  <Grid item xs={12}>
                    <NewProfileFormConfirm />
                  </Grid>
                )}

                <BottomButtonGroup
                  handleBack={() => handleBack()}
                  handleNext={() => handleNext()}
                  activeStep={activeStep}
                  isNextClickable={isNextClickable}
                  lastStep={3}
                />
              </Grid>
            </Container>
          </form>
        </FormProvider>
      </Box>
    </AuthLayout>
  );
};

export default New;
