import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";

import useHeaders from "hooks/useHeaders";
import { currentSchoolAtom, schoolIsLoggedInAtom } from "store/SchoolAuthStore";

import { favoriteTeachersAtom } from "store/FavoriteTeachersStore";
import { useAlertMessages } from "hooks/useAlertMessages";
import useCurrentSchool from "./useCurrentSchool";

const useFavoriteTeachersApi = () => {
  useCurrentSchool();
  const location = useLocation();
  const headers = useHeaders();
  const [currentSchool] = useAtom(currentSchoolAtom);
  const [schoolIsLoggedIn] = useAtom(schoolIsLoggedInAtom);
  const [, setFavoriteTeachers] = useAtom(favoriteTeachersAtom);
  const { addSuccessMessage, addErrorMessage } = useAlertMessages();

  const { mutate, isLoading } = useAspidaSWR(
    aspida(headers).api.v1.school_teachers._schoolTeacherId_string(
      currentSchool.id.toString()
    ).favorite_teachers,
    {
      enabled: schoolIsLoggedIn,
      onSuccess: (data) => {
        setFavoriteTeachers(data);
      },
      onError: () => {
        if (location.pathname !== "/teachers") {
          addErrorMessage("お気に入りの先生の取得に失敗しました。");
        }
      }
    }
  );

  const createFavoriteTeacher = async (teacherId: number) => {
    if (!schoolIsLoggedIn) return;

    try {
      const response = await aspida(headers)
        .api.v1.school_teachers._schoolTeacherId_string(
          currentSchool.id.toString()
        )
        .favorite_teachers.post({
          query: { teacher_id: teacherId }
        });

      if (response.status === 200) {
        await mutate();
        addSuccessMessage("お気に入りに登録しました。");
      }
    } catch (error) {
      addErrorMessage("お気に入り登録に失敗しました。");
    }
  };

  const removeFavoriteTeacher = async (favoriteTeacherId: number) => {
    if (!currentSchool) return;

    try {
      const response = await aspida(headers)
        .api.v1.school_teachers._schoolTeacherId_string(
          currentSchool.id.toString()
        )
        .favorite_teachers.destroy_by_teacher_id.delete({
          query: { teacher_id: favoriteTeacherId.toString() }
        });

      if (response.status === 200) {
        await mutate();
        addSuccessMessage("お気に入りを解除しました。");
      }
    } catch (error) {
      addErrorMessage("お気に入り解除に失敗しました。");
    }
  };

  return {
    isLoading,
    createFavoriteTeacher,
    removeFavoriteTeacher
  };
};

export default useFavoriteTeachersApi;
