import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import Meta from "components/organisms/Meta";
import Sidebar from "components/organisms/SchoolSidebar";
import CommonLayout from "components/layouts/CommonLayout";
// MEMO: このブロックだけ例外的にディレクトリ外からインポート
import TitleBlock from "features/schools/mypage/index/organisms/TitleBlock";

import type { Theme } from "@mui/material/styles";
import useFavoriteTeachers from "hooks/useFavoriteTeachers";
import TeachersList from "./organisms/TeachersList";

const FavoriteTeachers: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isLoading, createFavoriteTeacher, removeFavoriteTeacher } =
    useFavoriteTeachers();

  return (
    <CommonLayout>
      <Meta title="複業先生｜お気に入りの先生" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { sx: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem"
            }}
          >
            <TitleBlock title="お気に入りの先生" />
            <TeachersList
              isLoading={isLoading}
              createFavoriteTeacher={createFavoriteTeacher}
              removeFavoriteTeacher={removeFavoriteTeacher}
            />
          </Box>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default FavoriteTeachers;
