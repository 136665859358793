import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Stack, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";

import CommonLayout from "components/layouts/CommonLayout";

import type { Theme } from "@mui/material/styles";
import type React from "react";
import ChipGroup from "components/molecules/ChipGroup";
import Sidebar from "components/organisms/TeacherSidebar";
import CommonPagePagination from "components/organisms/CommonPagePagination";
import useMyLessonsPageApi from "./useApi";
import { kindOfTeacherLessonsType } from "./store";
import TitleBlock from "../../index/organisms/TitleBlock";
import LessonLists from "./organisms/LessonLists";

const MyLessons: React.FC = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const { isLoading, kindOfTeacherLessons, setKindOfTeacherLessons } =
    useMyLessonsPageApi();

  const LessonSelectLabelOptions: {
    label: string;
    value: kindOfTeacherLessonsType;
  }[] = [
    { label: "すべて", value: "" },
    { label: "授業準備中", value: "preparation" },
    { label: "授業終了・完了", value: "lesson_end" },
    { label: "授業キャンセル", value: "canceled" }
  ];

  return (
    <CommonLayout>
      <Meta title="複業先生｜授業進行管理" />
      <Box
        sx={{
          backgroundColor: theme.palette.backgroundColor?.main,
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: "1.5rem",
            padding: "1.5rem"
          }}
        >
          {!isMobile && <Sidebar />}
          <Stack gap={2} width="100%">
            <TitleBlock
              title="授業進行管理"
              description="成立した授業が一覧で確認できます。"
            />
            <ChipGroup
              options={LessonSelectLabelOptions}
              selectedValue={kindOfTeacherLessons}
              onSelect={setKindOfTeacherLessons}
            />
            <LessonLists isLoading={isLoading} />
            <CommonPagePagination />
          </Stack>
        </Container>
      </Box>
    </CommonLayout>
  );
};

export default MyLessons;
