import { atom } from "jotai";

import { Methods } from "api/api/v1/teachers/_teacherId@string/lessons/_lessonId@string";
import { Methods as LessonRequestCommentsMethods } from "api/api/v1/lesson_requests/_lessonRequestId/lesson_request_comments";
import { Methods as RatingMethods } from "api/api/v1/lessons/_lessonId@string/ratings";
import { Methods as BankAccountMethods } from "api/api/v1/teachers/_teacherId@string/teacher_bank_accounts";

export const detailsAtom = atom<
  Methods["get"]["resBody"]["details"] | undefined
>(undefined);

// MEMO: 授業の情報ではないため切り離す
export const isQuestionnaireAnsweredAtom =
  atom<Methods["get"]["resBody"]["is_questionnaire_answered"]>(false);

export const schoolTeacherAtom = atom<
  Methods["get"]["resBody"]["send_user"] | undefined
>(undefined);

export const supporterAtom = atom<
  Methods["get"]["resBody"]["supporter"] | undefined
>(undefined);

// MEMO: 紐付け先変更のタイミングで調整
export const lessonRequestCommentsAtom = atom<
  LessonRequestCommentsMethods["get"]["resBody"] | undefined
>(undefined);

export const meetingSheetInLessonAtom = atom<
  Methods["get"]["resBody"]["agenda_sheet"] | undefined
>(undefined);

export const ratingsAtom = atom<
  RatingMethods["get"]["resBody"]["details"] | undefined
>(undefined);

export const lessonMeetingPossibleDatetimesAtom = atom<
  | Methods["get"]["resBody"]["details"]["lesson_meeting_possible_datetimes"]
  | undefined
>(undefined);
export const bankAccountAtom = atom<
  BankAccountMethods["get"]["resBody"]["teacher_bank_account"] | undefined
>(undefined);

export const activeStepAtom = atom((get) => {
  const details = get(detailsAtom);

  switch (details?.step) {
    case "suggest_meeting_date":
    case "select_meeting_date":
    case "adjust_meeting_place":
    case "before_meeting":
      return 1;
    case "preparation":
      return 2;
    case "lesson_end":
    case "completed":
      return 3;
    default:
      return 0;
  }
});
