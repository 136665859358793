// TODO: 金額系の計算 (usePrice, useAmount)などをここにまとめる

import prices from "constants/prices";

export const getPrice = (schoolMinutes: number): number => {
  if (schoolMinutes > 120) {
    return prices.lesson3time;
  }
  if (schoolMinutes > 60) {
    return prices.lesson2time;
  }
  if (schoolMinutes > 0) {
    return prices.lesson1time;
  }
  return 0;
};

// オプションの金額を加算する純粋関数
export const getTotalAmount = (
  schoolMinutes: number,
  selectedOptionIds: number[],
  lessonOptions: { id: number; amount: number }[],
  // 初回の場合は基本料金のみを返す
  isMatchAccepted: boolean = true
): number => {
  const basePrice = getPrice(schoolMinutes);

  // 選択されたオプションをフィルタして価格を加算
  const selectedOptions = lessonOptions.filter((option) =>
    selectedOptionIds.includes(option.id)
  );
  const optionPrice = selectedOptions.reduce(
    (acc, option) => acc + option.amount,
    0
  );

  return isMatchAccepted ? basePrice + optionPrice : basePrice;
};
