import * as yup from "yup";
import { Methods } from "api/api/v1/teacher_profiles/_teacherProfileId@string";

type ProfileEditFormSchemaType = Methods["put"]["reqBody"];

const profileEditFormSchema: yup.ObjectSchema<ProfileEditFormSchemaType> = yup
  .object()
  .shape({
    id: yup.number().optional(),
    enthusiasm: yup.string().max(50, "50文字以内にしてください").optional(),
    introduction: yup.string().optional(),
    tag_ids: yup.array().optional().of(yup.number().required()),
    lesson_contents: yup
      .string()
      .max(250, "250文字以内にしてください")
      .optional(),
    organization_name: yup.string().optional(),
    origin_prefecture_id: yup.number().optional(),
    residence_prefecture_id: yup.number().optional(),
    origin_high_school_name: yup.string().optional(),
    origin_high_school_code: yup.string().optional(),
    origin_school_name: yup.string().optional(),
    origin_school_code: yup.string().optional(),
    origin_graduate_school_name: yup.string().optional(),
    image: yup.string().optional(),
    image_file_name: yup.string().optional(),
    teaching_licenses: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().optional(),
          special_subject: yup.string().optional()
        })
      )
      .optional(),
    license: yup.string().optional(),
    websites: yup
      .array()
      .optional()
      .of(
        yup.object().shape({
          title: yup.string().required("必須の項目です"),
          url: yup
            .string()
            .url("正しいURLを入力してください。")
            .required("必須の項目です")
        })
      ),
    is_limited_public: yup.boolean().optional(),
    is_reward: yup.boolean().optional(),
    can_interview: yup.boolean().optional(),
    is_mail_magazine: yup.boolean().optional(),
    birthday: yup.string().optional(),
    family_register_name: yup.string().optional()
  });

export default profileEditFormSchema;
