import { useAtomValue } from "jotai";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

import useTeacherRankBadge from "hooks/useTeacherRankBadge";

import type React from "react";
import { Methods } from "api/api/v1/school_teachers/_schoolTeacherId@string/favorite_teachers";
import FavoriteTeacherButton from "components/molecules/FavoriteTeacherButton";
// MEMO: このブロックだけ例外的にディレクトリ外からインポート
import RatingBox from "features/teachers/index/molecules/RatingBox";

import { favoriteTeacherIdsAtom } from "store/FavoriteTeachersStore";

import { shortSentences } from "utils/text";

import type { favoriteTeachersTypes } from "types/favoriteTeachersTypes";

type TeacherListItemCardProps = {
  teacher: NonNullable<Methods["get"]["resBody"]>[number];
} & favoriteTeachersTypes;

const TeacherListItemCard: React.FC<TeacherListItemCardProps> = (props) => {
  const {
    teacher: {
      teacher_id,
      name,
      image: { url: imageUrl },
      organization_name,
      enthusiasm,
      introduction,
      lesson_contents,
      teacher_rank,
      total_rating,
      rating_count,
      tags
    },
    createFavoriteTeacher,
    removeFavoriteTeacher
  } = props;
  const teacherRankBadge = useTeacherRankBadge(teacher_rank);
  const history = useHistory();
  const theme = useTheme();

  const favoriteTeacherIds = useAtomValue(favoriteTeacherIdsAtom);

  const handleClick = () => {
    history.push(`/teachers/${teacher_id}`);
  };

  const handleCreateFavoriteTeacher = () => {
    createFavoriteTeacher(teacher_id);
  };

  const handleRemoveFavoriteTeacher = () => {
    removeFavoriteTeacher(teacher_id);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        margin: "1rem auto"
      }}
      key={`${name} card box`}
    >
      <Card elevation={0} onClick={handleClick} sx={{ cursor: "pointer" }}>
        <CardContent sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              right: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem"
            }}
          >
            {total_rating && rating_count !== 0 && (
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <RatingBox
                  totalRating={total_rating / 10}
                  ratingCount={rating_count}
                />
              </Box>
            )}
            <FavoriteTeacherButton
              isFavorite={favoriteTeacherIds?.includes(teacher_id) || false}
              handleCreateFavoriteTeacher={handleCreateFavoriteTeacher}
              handleRemoveFavoriteTeacher={handleRemoveFavoriteTeacher}
            />
          </Box>
          <Grid container alignItems="center" spacing={1}>
            <Grid
              item
              sx={{
                display: "flex",
                marginRight: { xs: theme.spacing(1), md: theme.spacing(2) },
                marginBottom: { xs: theme.spacing(1), md: 0 }
              }}
            >
              <Box
                component="img"
                src={imageUrl}
                alt={`${name} avatar`}
                sx={{
                  display: "block",
                  width: { xs: "64px", md: "96px" },
                  height: { xs: "64px", md: "96px" },
                  objectFit: "cover"
                }}
              />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.sub.main} fontSize=".9rem">
                {organization_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize="1.2rem"
                  sx={{ lineHeight: 1.5 }}
                >
                  {name}
                </Typography>
                {teacherRankBadge.src && (
                  <Box
                    component="img"
                    src={teacherRankBadge.src}
                    alt={teacherRankBadge.alt}
                    sx={{
                      display: "block",
                      margin: "0 .5rem",
                      // MEMO: 解像度を合わせるための調整
                      width: "85px"
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          {total_rating && rating_count !== 0 && (
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <RatingBox
                totalRating={total_rating / 10}
                ratingCount={rating_count}
              />
            </Box>
          )}
          <Grid container alignItems="center" spacing={1}>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Grid
                item
                sx={{
                  width: { xs: "72px", md: "104px" },
                  marginRight: theme.spacing(2)
                }}
              />
            </Box>
            <Grid item xs>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      whiteSpace: "pre-wrap",
                      width: "100%"
                    }}
                  >
                    {shortSentences(enthusiasm)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      whiteSpace: "pre-wrap",
                      width: "100%"
                    }}
                  >
                    自己紹介
                  </Typography>
                  <Typography
                    sx={{
                      whiteSpace: "pre-wrap",
                      width: "100%"
                    }}
                  >
                    {shortSentences(introduction)}
                  </Typography>
                </Grid>
                {lesson_contents && (
                  <Grid item xs={12}>
                    <Typography
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "pre-wrap",
                        width: "100%"
                      }}
                    >
                      こんな授業ができます
                    </Typography>
                    <Typography
                      sx={{
                        whiteSpace: "pre-wrap",
                        width: "100%"
                      }}
                    >
                      {shortSentences(lesson_contents)}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.name}
                      variant="outlined"
                      color="default"
                      sx={{
                        margin: theme.spacing(1),
                        height: { xs: "24px", md: "36px" },
                        borderRadius: { xs: "12px", md: "18px" },
                        backgroundColor: theme.palette.backgroundColor?.main,
                        borderColor: theme.palette.muted?.main,
                        color: theme.palette.sub.main
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeacherListItemCard;
