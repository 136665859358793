import FormTextField from "components/molecules/FormTextField";

import type React from "react";

import { useAtomValue } from "jotai";
import { prefectures } from "features/teachers/profile/new/store";
import FormSelect from "components/molecules/FormSelect";
import OriginSchoolField from "features/teachers/profile/new/molecules/OriginSchoolField";
import ProfileEditContentsAccordion from "./ProfileEditContentsAccordion";

const CareerCard: React.FC = () => {
  const prefectureList = useAtomValue(prefectures);
  const prefectureOptions = prefectureList.map((p) => ({
    value: p.id,
    label: p.name
  }));

  return (
    <ProfileEditContentsAccordion
      title="経歴"
      label="career"
      fields={[
        "organization_name",
        "origin_prefecture_id",
        "residence_prefecture_id",
        "origin_high_school_name",
        "origin_school_name",
        "origin_graduate_school_name"
      ]}
    >
      <FormSelect
        name="origin_prefecture_id"
        label="出身地"
        options={prefectureOptions}
        isRequired
        fullWidth
      />
      <FormSelect
        name="residence_prefecture_id"
        label="居住地"
        options={prefectureOptions}
        isRequired
        fullWidth
      />
      <FormTextField
        name="organization_name"
        label="所属企業（学生の方は学校・学部名）"
        placeholder="例：〇〇株式会社"
      />
      <OriginSchoolField
        name="origin_high_school_name"
        codeName="origin_high_school_code"
        label="出身高校（または高等専門学校）"
        schoolDivision="high_school"
      />
      <OriginSchoolField
        name="origin_school_name"
        codeName="origin_school_code"
        label="出身大学（または専門学校）"
        schoolDivision="university"
      />
      <FormTextField
        name="origin_graduate_school_name"
        label="出身大学院等"
        placeholder="例：〇〇大学院"
        fullWidth
      />
    </ProfileEditContentsAccordion>
  );
};

export default CareerCard;
