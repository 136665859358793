import Axios from "axios";
import aspida from "libs/aspida";
import useAspidaSWR from "@aspida/swr";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import dayjs from "libs/dayjs";

import { useAlertMessages } from "hooks/useAlertMessages";

import {
  ContactCorsProxyLocal,
  ContactCorsProxyNetwork
} from "constants/ContactFormKeys";
import { afterQuestionnairesFormSchemaType } from "./constants/afterQuestionnairesFormSchema";
import {
  afterQuestionnairesProductionFormKeys,
  afterQuestionnairesDevelopmentFormKeys
} from "./constants/afterQuestionnairesFormKeys";

import { studentQuestionnairesDataAtom } from "../../studentQuestionnaires/new/store";

const useQuestionnairesApi = (lessonId: string, unique_id: string) => {
  const history = useHistory();
  const { addErrorMessage } = useAlertMessages();

  const [studentQuestionnairesData, setStudentQuestionnairesData] = useAtom(
    studentQuestionnairesDataAtom
  );

  const CorsProxy =
    process.env.REACT_APP_BUILD_ENV === "development"
      ? ContactCorsProxyLocal
      : ContactCorsProxyNetwork;
  const afterQuestionnairesFormKeys =
    process.env.REACT_APP_BUILD_ENV === "production"
      ? afterQuestionnairesProductionFormKeys
      : afterQuestionnairesDevelopmentFormKeys;

  const { isLoading } = useAspidaSWR(
    aspida({}).api.v1.lessons._lessonId_string(lessonId).student_questionnaires
      .new,
    {
      onSuccess: (data) => {
        setStudentQuestionnairesData(data);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          if (studentQuestionnairesData?.is_plain_questionnaires) {
            addErrorMessage(
              "URLがまちがっています。せんせいにきいてみてください。"
            );
            return;
          }
          addErrorMessage("URLが間違っています。先生に確認してください。");
          return;
        }
        if (studentQuestionnairesData?.is_plain_questionnaires) {
          addErrorMessage("アンケートをよみこめません");
          return;
        }
        addErrorMessage("アンケート情報の取得に失敗しました");
      },
      query: {
        unique_id
      }
    }
  );

  const submitAfterQuestionnaireForm = async (
    data: afterQuestionnairesFormSchemaType
  ) => {
    if (!studentQuestionnairesData) {
      addErrorMessage("アンケート情報の取得に失敗しているため送信できません");
      return;
    }

    if (studentQuestionnairesData?.exact_start_at) {
      const isAfterLesson = dayjs().isAfter(
        dayjs(studentQuestionnairesData?.exact_start_at)
      );

      if (!isAfterLesson) {
        if (studentQuestionnairesData?.is_plain_questionnaires) {
          addErrorMessage("じゅぎょうがおわってからこたえてください");
          return;
        }
        addErrorMessage("授業終了後に回答してください");
        return;
      }
    }

    const formattedData = new FormData();

    formattedData.append(
      afterQuestionnairesFormKeys.lessonRequestIdKeys,
      studentQuestionnairesData.lesson_request_id?.toString() || ""
    );
    formattedData.append(
      afterQuestionnairesFormKeys.schoolPrefectureNameKeys,
      studentQuestionnairesData?.school_prefecture_name || ""
    );
    formattedData.append(
      afterQuestionnairesFormKeys.schoolNameKeys,
      studentQuestionnairesData?.school_name || ""
    );

    formattedData.append(
      afterQuestionnairesFormKeys.schoolClassKeys,
      data.schoolClass
    );
    formattedData.append(
      afterQuestionnairesFormKeys.studentIDNumberKeys,
      data.studentIDNumber
    );
    formattedData.append(
      afterQuestionnairesFormKeys.learnWantedToKnowKeys,
      data.learnWantedToKnow
    );
    formattedData.append(
      afterQuestionnairesFormKeys.isUsefulKeys,
      data.isUseful
    );
    formattedData.append(
      afterQuestionnairesFormKeys.wantedToKnowMoreKeys,
      data.wantedToKnowMore
    );
    formattedData.append(
      afterQuestionnairesFormKeys.wantedToTellKeys,
      data.wantedToTell
    );

    await Axios.post(
      CorsProxy + afterQuestionnairesFormKeys.formUrl,
      formattedData
    )
      .then(() => {
        setStudentQuestionnairesData(undefined);
        if (studentQuestionnairesData?.is_plain_questionnaires) {
          history.push(
            `/lessons/${studentQuestionnairesData.lesson_id}/student_after_questionnaires/complete/hiragana`
          );
          return;
        }
        history.push(
          `/lessons/${studentQuestionnairesData.lesson_id}/student_after_questionnaires/complete`
        );
      })
      .catch((error) => {
        console.log(error.response);
        addErrorMessage("アンケートの送信に失敗しました");
      });
  };

  return {
    isLoading,
    submitAfterQuestionnaireForm
  };
};

export default useQuestionnairesApi;
