import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import FormSelect from "components/molecules/FormSelect";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormTextArea from "components/molecules/FormTextArea";
import FormSubmitButton from "components/molecules/FormSubmitButton";

import {
  schoolClassesOptions,
  isSurveyOptions,
  howMuchfunOptions,
  studentIDNumberOptions
} from "features/lessons/id/studentQuestionnaires/new/constants/questionnairesOptions";
import beforeQuestionnairesFormSchema, {
  beforeQuestionnairesFormSchemaType
} from "../constants/beforeQuestionnairesFormSchema";

type BeforeQuestionnairesFormProps = {
  submitForm: (data: beforeQuestionnairesFormSchemaType) => Promise<void>;
  disabled?: boolean;
};

const BeforeQuestionnairesForm: React.FC<BeforeQuestionnairesFormProps> = (
  props
) => {
  const { submitForm, disabled } = props;
  const theme = useTheme();

  const methods = useForm({
    resolver: yupResolver(beforeQuestionnairesFormSchema)
  });

  const handleSubmit = methods.handleSubmit(
    async (data: beforeQuestionnairesFormSchemaType) => {
      await submitForm(data);
    }
  );

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        padding: "1.5rem"
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <Typography variant="h2">
            以下のアンケートに回答してください
          </Typography>
          <FormSelect
            name="schoolClass"
            label="クラスを教えてください（該当する選択肢がない場合は先生の指示に従ってください）"
            options={schoolClassesOptions}
            isRequired
            fullWidth
            disabled={disabled}
          />
          <FormSelect
            name="studentIDNumber"
            label="あなたの番号を教えてください（該当する選択肢がない場合は先生の指示に従ってください）"
            isRequired
            options={studentIDNumberOptions()}
            fullWidth
            disabled={disabled}
          />
          <FormRadioGroup
            name="isSurvey"
            label="今回のテーマについて、すでに何か調べましたか？"
            options={isSurveyOptions}
            isRequired
            disabled={disabled}
          />
          <FormRadioGroup
            name="howMuchFun"
            label="今回の授業をどれくらい楽しみにしていましたか？"
            options={howMuchfunOptions}
            isRequired
            row
            disabled={disabled}
          />
          <FormTextArea
            name="wantToLearn"
            label="今回の授業で知りたいことを一つ教えてください"
            placeholder="例）どうして○○なのか知りたいです"
            isRequired
            disabled={disabled}
          />
          {/* MEMO: このような書き方をしないと、disabledが渡ってくるフォームについて、バリデーションによるdisabledが効かない */}
          <FormSubmitButton label="送信" {...(disabled ? { disabled } : {})} />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default BeforeQuestionnairesForm;
