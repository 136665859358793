import { Box, Typography } from "@mui/material";
import theme from "themes/theme";
import { useFormContext } from "react-hook-form";

import { isTrue } from "utils/checkBool";
import { formatDateString } from "utils/date";
import { useAtomValue } from "jotai";
import ConfirmField from "../molecules/ConfirmField";
import { prefectures } from "../store";

const ConfirmStep01: React.FC = () => {
  const { getValues } = useFormContext();
  const prefecturesData = useAtomValue(prefectures);
  const originPrefectureName = prefecturesData?.find(
    (prefecture) =>
      prefecture.id.toString() === getValues("origin_prefecture_id").toString()
  )?.name;
  const residencePrefectureName = prefecturesData?.find(
    (prefecture) =>
      prefecture.id.toString() ===
      getValues("residence_prefecture_id").toString()
  )?.name;

  const birthday = formatDateString(getValues("birthday"));

  const limitedPublicValue = isTrue(getValues("is_limited_public"))
    ? "教員アカウントのみに公開"
    : "一般公開";

  return (
    <Box
      component="section"
      sx={{
        padding: "1rem 1rem .5rem",
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        width: "100%"
      }}
    >
      <Typography variant="h2">基本情報</Typography>
      <ConfirmField title="氏名" value={getValues("name")} />
      <ConfirmField title="氏名（カナ）" value={getValues("kana_name")} />
      <ConfirmField title="電話番号" value={getValues("phone_number")} />
      <ConfirmField title="生年月日" value={birthday} />
      <ConfirmField title="出身地" value={originPrefectureName} />
      <ConfirmField title="居住地" value={residencePrefectureName} />
      <ConfirmField title="プロフィール公開設定" value={limitedPublicValue} />
      <ConfirmField
        title="報酬の有無"
        value={isTrue(getValues("is_reward")) ? "報酬あり" : "報酬なし"}
      />
    </Box>
  );
};
export default ConfirmStep01;
