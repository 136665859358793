import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

type FavoriteButtonProps = {
  selected?: boolean;
  createFavorite?: () => void;
  removeFavorite?: () => void;
};

const FavoriteButton: React.FC<FavoriteButtonProps> = (props) => {
  const { selected, createFavorite, removeFavorite } = props;
  const [isFavorite, setIsFavorite] = useState(selected || false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    // MEMO: 親コンポーネントにonClickイベントが用意されている場合には、イベントの伝播を止める
    event?.stopPropagation();
    if (isFavorite) {
      removeFavorite && removeFavorite();
    } else {
      createFavorite && createFavorite();
    }
    setIsFavorite(!isFavorite);
  };

  return (
    <IconButton onClick={(event) => handleClick(event)}>
      {isFavorite ? (
        <FavoriteIcon sx={{ color: "#fa004b" }} />
      ) : (
        <FavoriteBorderIcon />
      )}
    </IconButton>
  );
};

export default FavoriteButton;
