import React from "react";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";

import Meta from "components/organisms/Meta";
import QuestionnairesLayout from "components/layouts/QuestionnairesLayout";
import Loading from "components/atoms/Loading";

import useAfterLessonSurveyApi from "./useApi";
import LessonInfoBlock from "./organisms/LessonInfoBlock";
import AfterLessonSurveyForm from "./organisms/AfterLessonSurveyForm";

type paramsTypes = {
  id: string;
};

const AfterLessonSurveyPage: React.FC = () => {
  const { id } = useParams<paramsTypes>();

  const { isLoading, methods, postAfterLessonSurvey } =
    useAfterLessonSurveyApi(id);
  return (
    <QuestionnairesLayout title="授業後アンケート">
      <Meta
        title="複業先生｜授業後アンケート"
        description="今回の授業はどうでしたか？感想を教えてください。"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem"
          }}
        >
          <LessonInfoBlock />
          <AfterLessonSurveyForm
            methods={methods}
            onSubmit={postAfterLessonSurvey}
          />
        </Container>
      )}
    </QuestionnairesLayout>
  );
};

export default AfterLessonSurveyPage;
