import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

type ChangeQuestionnaireSwitchProps = {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & SwitchProps;

const ChangeQuestionnaireSwitch: React.FC<ChangeQuestionnaireSwitchProps> = (
  props
) => {
  const { label, onChange, checked, defaultChecked, ...field } = props;

  return (
    <FormControlLabel
      control={
        <Switch
          {...field}
          checked={checked}
          size="small"
          color="primary"
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

export default ChangeQuestionnaireSwitch;
