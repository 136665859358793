import { Box, Typography } from "@mui/material";
import theme from "themes/theme";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";

export const hoursOptions: { label: string; value: number }[] = [
  { label: "7時", value: 7 },
  { label: "8時", value: 8 },
  { label: "9時", value: 9 },
  { label: "10時", value: 10 },
  { label: "11時", value: 11 },
  { label: "12時", value: 12 },
  { label: "13時", value: 13 },
  { label: "14時", value: 14 },
  { label: "15時", value: 15 },
  { label: "16時", value: 16 },
  { label: "17時", value: 17 },
  { label: "18時", value: 18 },
  { label: "19時", value: 19 },
  { label: "20時", value: 20 },
  { label: "21時", value: 21 }
];

export const minutesOptions: { label: string; value: number }[] = [
  { label: "00分", value: 0 },
  { label: "05分", value: 5 },
  { label: "10分", value: 10 },
  { label: "15分", value: 15 },
  { label: "20分", value: 20 },
  { label: "25分", value: 25 },
  { label: "30分", value: 30 },
  { label: "35分", value: 35 },
  { label: "40分", value: 40 },
  { label: "45分", value: 45 },
  { label: "50分", value: 50 },
  { label: "55分", value: 55 }
];

export const schoolMinutesOptions = [
  { label: "45分", value: 45 },
  { label: "50分", value: 50 },
  { label: "60分", value: 60 },
  { label: "90分", value: 90 },
  { label: "120分", value: 120 },
  { label: "150分", value: 150 }
];

export const isPublicOptions = [
  { label: "公開", value: true },
  { label: "公開しない", value: false }
];

export const existenceOptions = [
  { label: "ある", value: true },
  { label: "ない", value: false }
];

export const rewardOptions = [
  { label: "報酬あり", value: true },
  { label: "報酬なし", value: false }
];

export const allowOptions = [
  { label: "許可する", value: true },
  { label: "許可しない", value: false }
];

export const mailMagazineOptions = [
  { label: "許可する", value: true },
  { label: "許可しない", value: false }
];

export const knowFromOptions = [
  { label: "SNS（facebook/Twitter/Instagram）", value: 1 },
  { label: "メディア（新聞/テレビ/雑誌）", value: 2 },
  { label: "インターネット検索", value: 3 },
  { label: "ご友人・知人からのご紹介", value: 4 }
];

export const isOnlineOptions = [
  { label: "オンライン", value: true },
  { label: "対面授業", value: false }
];

export const isLimitedPublicOptions = [
  {
    label: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <PublicIcon
          sx={{
            display: "block",
            color: theme.palette.sub.main,
            marginRight: ".5rem"
          }}
        />
        <Typography>一般公開</Typography>
      </Box>
    ),
    value: false
  },
  {
    label: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <LockIcon
          sx={{
            display: "block",
            color: theme.palette.sub.main,
            marginRight: ".5rem"
          }}
        />
        <Typography>教員アカウントのみに公開</Typography>
      </Box>
    ),
    value: true
  }
];

// お問い合わせの種類
export const contactTypes = [
  {
    label: "システムの利用方法について",
    value: "システムの利用方法について"
  },
  {
    label: "サービスの利用について",
    value: "サービスの利用について"
  },
  {
    label: "その他",
    value: "その他"
  }
];
