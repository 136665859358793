import type React from "react";
import { Box, Typography } from "@mui/material";
import FormTextField from "components/molecules/FormTextField";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import { allowOptions } from "constants/selectOptions";
import StepTitle from "../atoms/StepTitle";
import SitesField from "../molecules/SitesField";
import LicenseField from "../molecules/LicenseField";
import OriginSchoolField from "../molecules/OriginSchoolField";

const NewProfileFormStep3: React.FC = () => (
  <Box
    component="section"
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
      padding: "0.5rem 0"
    }}
  >
    <StepTitle stepName={["その他の経歴を埋めることで選ばれやすくなります"]} />
    <Typography variant="caption">
      こちらの項目はすべて任意ですが、入力いただくことでマッチング率をさらに高めることができます。登録後も編集可能ですので、お時間のあるときにご入力いただければ幸いです。
    </Typography>
    <FormTextField
      name="organization_name"
      label="所属企業（学生の方は学校・学部名）"
      placeholder="例：〇〇株式会社"
    />
    <OriginSchoolField
      name="origin_high_school_name"
      codeName="origin_high_school_code"
      label="出身高校（または高等専門学校）"
      schoolDivision="high_school"
    />
    <OriginSchoolField
      name="origin_school_name"
      codeName="origin_school_code"
      label="出身大学（または専門学校）"
      schoolDivision="university"
    />
    <FormTextField
      name="origin_graduate_school_name"
      label="出身大学院等"
      placeholder="例：〇〇大学院"
      fullWidth
    />
    <LicenseField />
    <FormTextField
      name="license"
      label="その他の免許・資格"
      placeholder="例：潜水士免許"
      fullWidth
    />
    <SitesField />
    <FormRadioGroup
      name="can_interview"
      label="メディアからの取材"
      options={allowOptions}
      supplementalText="許可するを選択した場合、LX事務局を通じて、授業に取材が入ることがあります。"
    />
  </Box>
);

export default NewProfileFormStep3;
