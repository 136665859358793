import { Box, Typography } from "@mui/material";
import FormBirthdayField from "components/molecules/FormBirthdayField";
import FormFullNameField from "features/teachers/profile/new/molecules/FormFullNameField";
import FormRadioGroup from "components/molecules/FormRadioGroup";
import FormSelect from "components/molecules/FormSelect";
import FormTextField from "components/molecules/FormTextField";
import { isLimitedPublicOptions, rewardOptions } from "constants/selectOptions";

import StepTitle from "features/teachers/profile/new/atoms/StepTitle";

import type React from "react";
import { useFormContext } from "react-hook-form";
import { useAtomValue } from "jotai";
import { isTrue } from "utils/checkBool";
import { prefectures } from "../store";

const NewProfileFormStep1: React.FC = () => {
  const prefectureList = useAtomValue(prefectures);
  const prefectureOptions = prefectureList.map((p) => ({
    value: p.id,
    label: p.name
  }));

  const { watch } = useFormContext();
  const isLimitedPublic = watch("is_limited_public");
  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "0.5rem 0"
      }}
    >
      <StepTitle stepName={["基本情報の入力をお願いします"]} />
      <FormFullNameField label="氏名" isRequired />
      <FormTextField
        name="phone_number"
        label="電話番号（ハイフンなし）"
        isRequired
        supplementalText="審査内容について、事務局からご連絡をする場合があります。"
        placeholder="例：01234567890"
        isPrivate
      />
      <FormBirthdayField
        name="birthday"
        label="生年月日"
        isRequired
        placeholders={{
          year: "1990",
          month: "1",
          date: "1"
        }}
        supplementalText="本人確認の際に利用するため、一般公開はされません。"
        isPrivate
      />
      <FormSelect
        name="origin_prefecture_id"
        label="出身地"
        options={prefectureOptions}
        isRequired
        fullWidth
      />
      <FormSelect
        name="residence_prefecture_id"
        label="居住地"
        options={prefectureOptions}
        isRequired
        fullWidth
      />
      <FormSelect
        name="is_limited_public"
        label="プロフィール公開設定"
        options={isLimitedPublicOptions}
        isRequired
        fullWidth
      />
      <Box>
        <Typography>
          {isTrue(isLimitedPublic)
            ? "教員アカウントのみに公開されます。"
            : "公開されます。"}
        </Typography>
        <Typography variant="caption">
          {isTrue(isLimitedPublic) ? (
            <>
              公開にするとより多くの人に見てもらえます。
              <br />
              サイトに登録していない人からは閲覧できません。
            </>
          ) : (
            "サイトに登録していない人からでも閲覧できます。"
          )}
        </Typography>
      </Box>
      <FormRadioGroup
        name="is_reward"
        label="報酬の有無"
        options={rewardOptions}
        isRequired
        supplementalText="報酬なしを選択した場合は、地域のNPO等に寄付されます。"
      />
    </Box>
  );
};

export default NewProfileFormStep1;
