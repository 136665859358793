import React from "react";
import { useAtomValue } from "jotai";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { formatDateTimeStringWithDay } from "utils/date";
import { studentQuestionnairesDataAtom } from "../../store";
import LessonInfoItem from "../../molecules/LessonInfoItem";

const LessonInfoBlock: React.FC = () => {
  const theme = useTheme();

  const studentQuestionnairesData = useAtomValue(studentQuestionnairesDataAtom);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: `1px solid ${theme.palette.border?.main}`,
        backgroundColor: theme.palette.common.white,
        borderRadius: "4px",
        padding: "1.5rem"
      }}
    >
      <Typography variant="h2">じゅぎょうについて</Typography>
      <LessonInfoItem
        title="がっこうのなまえ"
        value={studentQuestionnairesData?.school_name}
      />
      <LessonInfoItem
        title="じゅぎょうがあったひ"
        value={
          studentQuestionnairesData?.exact_start_at &&
          formatDateTimeStringWithDay(studentQuestionnairesData.exact_start_at)
        }
      />
      <LessonInfoItem
        title="じゅぎょうをしてくれるせんせい"
        value={
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center"
            }}
          >
            <Avatar
              src={studentQuestionnairesData?.teacher_image?.url}
              alt={`${studentQuestionnairesData?.teacher_name} avatar`}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: ".25rem"
              }}
            >
              <Typography fontSize=".8rem">
                {studentQuestionnairesData?.teacher_name_kana}
              </Typography>
              <Typography fontWeight="bold">
                {studentQuestionnairesData?.teacher_name}
              </Typography>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default LessonInfoBlock;
