import Typography from "@mui/material/Typography";

import type React from "react";
import { Stack } from "@mui/material";

type StepTitleProps = {
  stepName: string[];
  supplementMsg?: string;
};

const StepTitle: React.FC<StepTitleProps> = ({ stepName, supplementMsg }) => (
  <Stack gap={1} py={2}>
    <Typography variant="h2">
      {stepName.map((element, index) => (
        <Typography
          component="span"
          key={index}
          fontWeight="bold"
          fontSize="1.2rem"
          sx={{ display: "inline-block", lineHeight: "1.5" }}
        >
          {element}
        </Typography>
      ))}
    </Typography>
    {supplementMsg && (
      <Typography variant="body1" sx={{ fontSize: "1em" }}>
        {supplementMsg}
      </Typography>
    )}
  </Stack>
);

export default StepTitle;
